import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { SidebarData } from "../../Data/Data";
import { Link } from "react-router-dom";

const Sidebar = ({ index }) => {
  const [selected, setSelected] = useState(index);
  useEffect(() => {
    setSelected(index);
  }, []);
  console.log({ selected });
  return (
    <div className="menu">
      {SidebarData.map((item, index) => {
        return (
          <Link
            to={"/" + item.path}
            className="text-decoration-none"
            // onClick={() => setSelected(index)}
            key={index}
          >
            <div className={selected === index ? "menu-item2" : "menu-item1"}>
              <item.icon className="itemIcon" />
              <span
                className={selected === index ? "itemHeading1" : "itemHeading"}
              >
                {item.heading}
              </span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Sidebar;
