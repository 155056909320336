import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./Table.css";
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

export const CustomerTable = () => {
  const [type, setType] = useState("");
  const handleEditCustomer = (item) => {
    setDataArr(item);
    setType("Edit");
  };
  const addNewRecord = () => {
    setDataArr({ name: "", email: "", phone: "", usertype: "Admin" });
    setType("Add");
  };
  const [validated, setValidated] = useState(false);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Mobile",
      selector: (row) => row.phone,
      sortable: true,
      width: "10%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "25%",
    },
    {
      name: "Address",
      selector: (row) => row.createdAt,
    },
    {
      name: "Emergency Contact",
      selector: (row) => row.phone,
      sortable: true,
      width: "15%",
    },
    {
      name: "Credits",
      selector: (row) => row.usertype,
      sortable: true,
      width: "10%",
    },
    {
      name: "ACTION",
      selector: (row) => (
        <div className="d-flex align-items-center justify-content-around">
          <i
            className="bi bi-pencil-fill tbl_icon"
            style={{ color: "#00C965" }}
            onClick={() => handleEditCustomer(row)}
            data-bs-toggle="modal"
            data-bs-target="#edit-customer"
          ></i>
          {/* <i className="bi bi-trash3-fill tbl_icon px-3" onClick={() => handleDe(row._id)} style={{ color: "#F94D32" }}></i> */}
          <Link to={"/view-user"} state={row}>
            <i
              className="bi bi-info-circle-fill tbl_icon"
              style={{ color: "#11B5BC" }}
            ></i>
          </Link>
        </div>
      ),
      center: true,
    },
  ];

  const [data, setData] = useState();
  const getCustomers = async (e) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/getUser`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    console.log(res.data);
    setData(
      res.data.data.filter(function(e) {
        return e.usertype === "Admin";
      })
    );
  };
  useEffect(() => {
    getCustomers();
  }, []);

  // edit code
  const [dataArr, setDataArr] = useState({
    name: "",
    email: "",
    phone: "",
    usertype: "Admin",
  });

  const handleSubmit = async (e) => {
    dataArr.usertype = "Admin";
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      const url =
        type === "Edit"
          ? `${process.env.REACT_APP_API_URL}/api/user/updateCustomer/` +
            dataArr._id
          : `${process.env.REACT_APP_API_URL}/api/user/create`;
      const response = await fetch(url, {
        method: type === "Edit" ? "PUT" : "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataArr),
      });
      const json = await response.json();
      console.log(json);
      if (json.status === true) {
        document.getElementById("btnclose").click();
        getCustomers();
      }
    }

    form.classList.add("was-validated");

    setValidated(true);
  };
  return (
    <>
      <div className="maindash-orders">
        <div className=" w-100 d-flex align-items-center justify-content-between">
          <div className="heading-customers">Admin</div>
          <div className="d-flex">
            <input className="search-items" placeholder="Search user.." />
            <button
              className="btn btn-success rounded-pill"
              onClick={() => addNewRecord()}
              data-bs-toggle="modal"
              data-bs-target="#edit-customer"
            >
              + Add
            </button>
          </div>
        </div>
        <div className="w-100">
          <DataTable
            pagination
            selectableRowsComponentProps={selectProps}
            dense
            responsive={true}
            columns={columns}
            data={data}
            highlightOnHover
            pointerOnHover // customStyles={customStyles}
          />
        </div>
      </div>

      <div
        className="modal fade"
        id="edit-customer"
        tabIndex="-1"
        aria-labelledby="edit-customerLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="edit-customerLabel">
                {type} User
              </h1>
              <button
                type="button"
                className="btn-close"
                id="btnclose"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
                validated={validated.toString()}
                method="post"
              >
                <div className="mb-3">
                  <input
                    type="name"
                    name="name"
                    value={dataArr.name}
                    onChange={(e) =>
                      setDataArr({ ...dataArr, name: e.target.value })
                    }
                    className="form-control input-field"
                    id="username"
                    placeholder="Enter full name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    value={dataArr.email}
                    onChange={(e) =>
                      setDataArr({ ...dataArr, email: e.target.value })
                    }
                    className="form-control input-field"
                    placeholder="Enter email address"
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control input-field"
                    value={dataArr.phone}
                    onChange={(e) =>
                      setDataArr({ ...dataArr, phone: e.target.value })
                    }
                    placeholder="Enter mobile number"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-success w-100 rounded-pill my-1 py-2"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTable;
