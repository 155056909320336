import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Product from "../../images/productDetails.png";
import Star from "../../images/star.png";
import "./Shop.css";

export const ShopItems = () => {
  const [reqtype, setReqtype] = useState("");
  const [data, setData] = useState({
    name: "",
    price: "",
    discountedPrice: "",
    categoryId: "",
    description: "",
  });
  const addProduct = () => {
    setData({
      name: "",
      price: "",
      discountedPrice: "",
      categoryId: "",
      description: "",
      _id: "",
    });
    setReqtype("Add");
  };
  const [validated, setValidated] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const getCategory = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/category`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    if (json.status === "Success") {
      setCategoryData(json.data);
    }
  };
  const [productData, setProductData] = useState([]);
  const getProduct = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    if (json.status === true) {
      setProductData(json.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    var formData = new FormData(e.currentTarget);
    for (var key of formData.entries()) {
      console.log(key);
    }
    // console.log(formData);
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add("was-validated");
      setValidated(true);
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/products`,
        {
          method: reqtype === "Add" ? "POST" : "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: formData,
        }
      );
      const json = await response.json();
      if (json.status === "Success") {
        getProduct();
        document.getElementById("btn-close").click();
      }
    }
  };

  const handleDeleteProduct = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: id }),
      }
    );
    const json = await response.json();
    console.log(json);
    if (json.status === "Success") {
      getProduct();
    }
  };

  const handleEditProduct = async (item) => {
    setData(item);
    setReqtype("Edit");
  };

  useEffect(() => {
    getProduct();
    getCategory();
  }, []);

  return (
    <>
      <div className="maindash-orders">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div className="heading-customers">Shop</div>
          <div className="d-flex">
            <input className="search-items" placeholder="Search Customer.." />
            <button
              className="btn btn-success rounded-pill"
              onClick={() => addProduct("Add")}
              data-bs-toggle="modal"
              data-bs-target="#addCustomerModal"
            >
              + Add
            </button>
          </div>
        </div>
        <div className="row w-100">
          {productData &&
            productData.map((item, index) => (
              <div className="col-lg-3 col-sm-6 col-md-3" key={index}>
                <div className="card custom-card mb-3">
                  <div className="product-card">
                    <div className="hide-btn">
                      <button
                        className="btn"
                        onClick={() => handleEditProduct(item)}
                        data-bs-toggle="modal"
                        data-bs-target="#addCustomerModal"
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </button>
                      <button
                        className="btn mx-2"
                        onClick={() => handleDeleteProduct(item._id)}
                      >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                      <Link
                        to={"/admin-product-details"}
                        state={item}
                        className="btn"
                      >
                        <i
                          className="bi bi-arrow-right"
                          style={{ fontWeight: "500" }}
                        ></i>
                      </Link>
                    </div>
                    <img
                      //   src={Product}
                      src={`${process.env.REACT_APP_API_URL}/uploads/${item.images[0]}`}
                      className="card-img-top"
                      alt="..."
                      height={170}
                      width={120}
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-title">{item.name}</div>
                    <strong className="card-text">${item.price}</strong>
                    <br />
                    <img src={Star} alt="" height={17} />{" "}
                    {item.reviews.map((review) => (
                      <span>{review.starRating}</span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div
        className="modal fade"
        id="addCustomerModal"
        tabIndex="-1"
        aria-labelledby="addCustomerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addCustomerModalLabel">
                {reqtype} Product
              </h1>
              <button
                type="button"
                className="btn-close"
                id="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
                validated={validated.toString()}
                method="post"
                encType="multipart/form-data"
              >
                <input name="_id" type="hidden" value={data._id} />
                <div className="mb-3">
                  <input
                    className="form-control input-field"
                    type="file"
                    name="productPicture"
                    id="formFile"
                    required=""
                    multiple
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="name"
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    className="form-control input-field"
                    placeholder="Product Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    name="price"
                    value={data.price}
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                    className="form-control input-field"
                    placeholder="Product Price"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    name="discountedPrice"
                    value={data.discountedPrice}
                    onChange={(e) =>
                      setData({ ...data, discountedPrice: e.target.value })
                    }
                    className="form-control input-field"
                    placeholder="Product Discount Price"
                    required
                  />
                </div>
                <div className="mb-3">
                  <select
                    className="form-select input-field"
                    name="categoryId"
                    value={data.categoryId}
                    onChange={(e) =>
                      setData({ ...data, categoryId: e.target.value })
                    }
                  >
                    <option value="" disabled key={0}>
                      Open this select menu
                    </option>
                    {categoryData &&
                      categoryData.map((item, i) => (
                        <option key={i + 1} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control input-field"
                    name="description"
                    value={data.description}
                    onChange={(e) =>
                      setData({ ...data, description: e.target.value })
                    }
                    placeholder="Product Description"
                    rows="3"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-success w-100 rounded-pill my-2 py-2"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
