import { Container } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";

const Distributor = () => {
  return (
    <>
      <section className="distributor">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h3 className="mb-4">Become A Distributor</h3>
              <p>
                Become a Safewent Distributor and join a revolutionary network of safety enthusiasts. As a distributor, you have the opportunity to promote and sell Safewent products in your area, ensuring that more people can benefit from our cutting-edge safety solutions.
              </p><br/>
              <p>By partnering with us, you gain access to a wide range of benefits. You'll receive comprehensive training and support to help you understand our products inside out and effectively communicate their value to potential customers. As a distributor, you'll enjoy attractive profit margins and incentives for achieving sales targets.</p><br/>
              <p>Safewent provides you with the tools and resources you need to succeed. We offer marketing materials, product samples, and ongoing promotional campaigns to generate awareness and drive demand for Safewent products. You'll also have access to a dedicated distributor portal, where you can manage your sales, track your earnings, and stay updated on the latest product updates.</p><br/>
              <p>Join us in creating a safer world by becoming a Safewent Distributor. Together, we can empower individuals with the tools they need to enhance their personal safety and well-being.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Distributor;
