import "./sosCall.css";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import backArrow from "../../assests/arrow left.png";
import callBtn from "../../assests/call.png";
import msgBtn from "../../assests/msg.png";

import {
  fetchSosCallDetails,
  getReasons,
  sendSosCall,
} from "../../actions/sosCall";
import InputBox from "../input/input";

function SosCallPage() {
  const navigate = useNavigate();
  //   const options = [
  //     { value: "chocolate", label: "Chocolate" },
  //     { value: "strawberry", label: "Strawberry" },
  //     { value: "vanilla", label: "Vanilla" },
  //   ];

  const { qrcodename } = useParams();
  const [sosCallDetails, setSosCallDetails] = useState(null);
  const [reasons, setReasons] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);

  async function handleFetchReasons() {
    const response = await getReasons();
    console.log({ response });
    const options = response.data.data.map((option) => ({
      value: option.reasonText,
      label: option.reasonText,
      id: option._id
    }));
    if (response.status === 200) setReasons(options);
  }

  async function handleFetchSosDetails() {
    try {
      const response = await fetchSosCallDetails({ qrcodename });
      console.log({ response });
      if (response.status === 200)
        setSosCallDetails({
          vehical: response.data.data.vehical,
          user: response.data.data.user,
        });
    } catch (err) {
      console.log(err);
    }
  }
  async function handleSubmitSosCall(e) {
    e.preventDefault();
    if (!selectedReason) return alert("Please select a reason");

    const formData = new FormData(e.target);
    formData.append("reasonId", selectedReason);
    formData.append("vehicleNumber", sosCallDetails.vehical.registrationNumber);
    formData.append("user", sosCallDetails.user._id);
    const response = await sendSosCall(formData);
    if (response.status === 201) {
      navigate("/success");
    }
    console.log({ response });
    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
  }

  useEffect(() => {
    if (qrcodename) {
      handleFetchSosDetails();
      handleFetchReasons();
    }
  }, [qrcodename]);

  // console.log(sosCallDetails);
  // console.log(qrcodename );


  return (
    <>
      {!sosCallDetails ? (
        <div className="loaderContainer">
          <div className="loader">loading...</div>
        </div>
      ) : (
        <div className="sosCallPage">
          <div className="head">
            <img src={backArrow} />
            <h4 className="registrationNumber">
              {sosCallDetails.vehical.registrationNumber}
            </h4>

            {sosCallDetails?.user?.showDetails && (

              <h3>This vehicle belongs to</h3>
            )}
          </div>
          {
            sosCallDetails?.user?.showDetails && (

              <div className="userCard">
                <div className="userDetailsContainer">
                  {/* <div className="img-container"></div> */}
                  {/* <img src={`${process.env.REACT_APP_API_URL}/api/fetchmedia/` + item.imageUrl} alt="" /> */}
                  <div className="userDetails">
                    <h5>{sosCallDetails.user.name}</h5>
                    <p>
                      {sosCallDetails.user.countryCode}
                      {sosCallDetails.user.phone}
                    </p>
                  </div>
                </div>
                <div className="actions">
                  <a
                    href={`tel:${sosCallDetails.user.countryCode}${sosCallDetails.user.phone}`}
                  >
                    <img src={callBtn} alt="" />
                  </a>
                  <a
                    href={`sms:${sosCallDetails.user.countryCode}${sosCallDetails.user.phone}`}
                  >
                    <img src={msgBtn} alt="" />
                  </a>
                </div>
              </div>


            )
          }

          <form
            id="sosCallForm"
            className="reasonSelectContainer"
            onSubmit={handleSubmitSosCall}
          >
            <p>Mark Vehicle</p>
            <Select
              options={reasons}
              onChange={(option) => {
                console.log(option);
                setSelectedReason(option.id)
              }
              }
            />
            <div className="inputsContainer">
              <InputBox
                label="Your Name"
                name="creatorName"
                placeholder="Enter your name"
                required
              />
              <InputBox
                // type="number"
                maxLength="10"
                inputMode="numeric"
                label="Your Mobile Number"
                name="creatorNumber"
                placeholder="Enter your mobile number"
                required
              />
            </div>
          </form>
          <input
            className="sosCallBtn"
            form="sosCallForm"
            type="submit"
            value="SOS Call"
          />
        </div>
      )}
    </>
  );
}

export default SosCallPage;
