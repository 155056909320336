import React from "react";
// import Table from '../Table/Table'
import Table from "../Table/CustomerTable";
import "./Customer.css";
import { AddCustomer } from "../Modal/AddCustomer";

export const CustomerData = () => {
  return (
    <>
      <AddCustomer />
      <div className="maindash-orders">
        <div className=" w-100 d-flex align-items-center justify-content-between">
          <div className="heading-customers">Customers</div>
          <div className="d-flex">
            <button
              className="btn btn-success rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#addCustomerModal"
            >
              + Add
            </button>
          </div>
        </div>
        <Table />
      </div>
    </>
  );
};
