import React, { createRef, useEffect} from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppStore from "../../images/app_store_btn.png";
import PlayStore from "../../images/play_store_btn.png";
import LogoImage from "../../images/logoLinear.svg";

const Header = () => {
  const ref = createRef();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 10) {
        ref.current.classList.add("scrolled");
      } else {
        ref.current.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [ref]);

  return (
    <>
      <header ref={ref} className="top-bar">
        <Container>
          <Row className="align-items-center">
            <Col xs={6}>
              <a href="#">
                <img src={LogoImage} alt="" />
              </a>
            </Col>
            <Col xs={6} className="text-end">
              <a href="https://wa.me/+918902983066" className="send-msg">Send Message</a>
            </Col>
          </Row>
        </Container>
      </header>
      <section className="hero-section">
        <div className="hero-section-content w-100">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <h1>
                  Create better <br />
                  together, Everywhere
                </h1>
                <p className="mb-5">Largest community for Road Safety</p>
                <div className="d-flex align-item-center flex-wrap">
                  <Row>
                    <Col xs={6}>
                      <a href="https://apps.apple.com/us/app/safewent/id6450649965">
                        <img src={AppStore} alt="" />
                      </a>
                    </Col>
                    <Col xs={6}>
                      <a href="https://play.google.com/store/apps/details?id=com.todquest.safewent">
                        <img src={PlayStore} alt="" />
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Header;
