import React, { useState } from 'react'
// import axios from 'axios';
import './Modal.css';
export const AddCustomer = () => {
    const [validated, setValidated] = useState(false)

    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        usertype: 'Customer'
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(data);
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/create`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            });
            const json = await response.json()
            console.log(json);
        }

        form.classList.add('was-validated')

        setValidated(true)
    }

    return (
        <>
            <div className="modal fade" id="addCustomerModal" tabIndex="-1" aria-labelledby="addCustomerModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addCustomerModalLabel">Add Customer</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} className='needs-validation' noValidate validated={validated.toString()} method="post">
                                <div className="mb-3">
                                    <input type="name" name='name' value={data.name} onChange={e => setData({ ...data, name: e.target.value })} className="form-control input-field" id="username" placeholder="Enter full name" required />
                                </div>
                                <div className="mb-3">
                                    <input type="email" value={data.email} onChange={e => setData({ ...data, email: e.target.value })} className="form-control input-field" placeholder="Enter email address" required />
                                </div>
                                <div className="input-group mb-3">
                                    
                                    <input type="number" className="form-control input-field" value={data.phone} onChange={e => setData({ ...data, phone: e.target.value })} placeholder='Enter mobile number' required />
                                </div>

                                <button type="submit" className="btn btn-success w-100 rounded-pill my-2 py-2">Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
