import React from 'react'
import './Dashboard.css'
// import SearchOutLinedIcon from '@mui/icons-material/SearchOutLined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Sidebar from '../Sidebar/Sidebar';
import Logo from '../../images/logo.png';
import MainDash from '../MainDash/MainDash';

function Dashboard() {
    return (
        <div className='admin-root1-div'>


            <div className='admin-navbar'>
                <div className='admin-company'>

                    <div className='admin-logo'>
                        <img className='admin-logo-icon' src={Logo} alt='' />
                    </div>

                    <span className='admin-heading'>
                        SAFEWENT
                    </span>

                </div>

                <div className='admin-wrapper'>
                    <input className='search-bar' type="text" placeholder='Search...' />
                    <NotificationsNoneIcon className='noti' />
                </div>
            </div>



            <div className='admin-body'>
                <Sidebar />
                {/* <div className='orders'>Orders</div> */}
                <MainDash />
            </div>
        </div>
    )
}

export default Dashboard
