import React, { useState } from "react";
import "./Login.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import env from "../../config/env";

const SignIn = () => {
  let history = useNavigate();
  const [otp, setOtp] = useState("");

  const handleSubmit = async (e) => {
    console.log(localStorage.getItem("phone"));
    e.preventDefault();
    const response = await fetch(`${env.apiUrl}/api/user/otp-verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp: otp,
        phone: localStorage.getItem("phone"),
        countryCode: "+91",
      }),
    });
    const json = await response.json();
    console.log(json);
    if (json.status === "Success") {
      localStorage.setItem("token", json.token);
      history("/admin-dashboard");
    } else {
      alert(json.message);
    }
  };

  return (
    <>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-4">
              <h1 className="text-center">Verify OTP</h1>
              <p className="text-center text-18-500 text-gray3 mb-4">
                Enter OTP which you received for login
              </p>
              <p className="text-center text-16-500 text-gray4">
                Expires in 1 minutes
              </p>
              <form
                onSubmit={handleSubmit}
                className="verify-otp"
                method="post"
              >
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={4}
                  inputClassName="otpInput"
                  separator={<span> </span>}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 1.5rem",
                    fontSize: "2rem",
                    borderRadius: 7,
                    border: "2px solid #c6c7c8",
                  }}
                />

                <p className="mt-3 text-muted text-center">
                  Didn’t get otp?{" "}
                  <span className="signup-link">Send Again</span>
                </p>

                <button
                  className="btn btn-success btn-lg rounded-pill w-100"
                  type="submit"
                >
                  Verify OTP
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
