import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./Table.css";
import { apiCaller } from "../../utils/apiCaller";
import env from "../../config/env";
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

export const CustomerTable = () => {
  const handleEditCustomer = (item) => {
    setDataArr(item);
  };
  const [validated, setValidated] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [Vehicles, setVehicles] = useState([]);

  const fetchEmergencyContact = async (userId) => {
    try {
      const contacts = await apiCaller.get(
        `${env.apiUrl}/api/sosContacts/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (contacts) {
        const list = contacts.result.contacts;
        if (typeof list === "string") {
          setContacts(JSON.parse(list));
        } else {
          setContacts(list);
        }
      } else {
        setContacts([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchVehicles = async (userId) => {
    try {
      const vehicle = await apiCaller.get(
        `${env.apiUrl}/api/uservehicle/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (vehicle) {
        setVehicles(vehicle.data);
      } else {
        setVehicles([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Mobile",
      selector: (row) => row.phone,
      sortable: true,
      width: "10%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "25%",
    },
    {
      name: "Blood Group",
      selector: (row) => row.bloodGroup,
      width: "10%",
    },
    {
      name: "Address",
      selector: (row) => row.createdAt,
    },
    {
      name: "Emergency Contact",
      selector: (row) => (
        <div className="d-flex align-items-center justify-content-around">
          <i
            className="bi bi-eye tbl_icon"
            style={{ color: "#00C965" }}
            onClick={() => fetchEmergencyContact(row._id)}
            data-bs-toggle="modal"
            data-bs-target="#view-contact"
          ></i>
        </div>
      ),
      width: "10%",
    },
    {
      name: "Vehicle Number",
      selector: (row) => (
        <div className="d-flex align-items-center justify-content-around">
          <i
            className="bi bi-eye tbl_icon"
            style={{ color: "#00C965" }}
            onClick={() => fetchVehicles(row._id)}
            data-bs-toggle="modal"
            data-bs-target="#view-vehicleNo"
          ></i>
        </div>
      ),
      width: "10%",
    },
    {
      name: "ACTION",
      selector: (row) => (
        <div className="d-flex align-items-center justify-content-around">
          <i
            className="bi bi-pencil-fill tbl_icon"
            style={{ color: "#00C965" }}
            onClick={() => handleEditCustomer(row)}
            data-bs-toggle="modal"
            data-bs-target="#edit-customer"
          ></i>
          {/* <i className="bi bi-trash3-fill tbl_icon px-3" onClick={() => handleDe(row._id)} style={{ color: "#F94D32" }}></i> */}
          <Link to={"/view-customer"} state={row}>
            <i
              className="bi bi-info-circle-fill tbl_icon"
              style={{ color: "#11B5BC" }}
            ></i>
          </Link>
        </div>
      ),
      center: true,
    },
  ];

  const [data, setData] = useState();
  const [query, setQuery] = useState("");
  const [vehicleQuery, setVehicleQuery] = useState("");
  const [filteredUser, setfilteredUser] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const getCustomers = async (e) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/getUser`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const list = res.data.data;
    const newlist = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.usertype === "Customer") {
        const elem = newlist.find(
          (v) => v.email === item.email && v.phone === item.phone
        );
        if (!elem) {
          newlist.push(item);
        }
      }
    }

    setData(newlist);
  };
  const getVehicles = async (e) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/uservehicle`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    setVehicleList(res.data.data);
  };
  useEffect(() => {
    getCustomers();
    getVehicles();
  }, []);

  const handleQueryChange = (e) => {
    const value = e.target.value;
    setQuery(value);
  };

  const handleVehicleQueryChange = (e) => {
    const value = e.target.value;
    setVehicleQuery(value);
  };

  useEffect(() => {
    if (query) {
      const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );

      setfilteredUser(filteredData);
    } else {
      setfilteredUser(data);
    }
  }, [query, data]);

  useEffect(() => {
    if (vehicleQuery) {
      const filteredVehicleList = vehicleList.filter((item) =>
        item.registrationNumber
          .toLowerCase()
          .includes(vehicleQuery.toLowerCase())
      );
      const list = [];

      for (let i = 0; i < filteredVehicleList.length; i++) {
        const vehicle = filteredVehicleList[i];
        for (let j = 0; j < data.length; j++) {
          const user = data[j];
          if (vehicle.userid == user._id) list.push(user);
        }
      }
      setfilteredUser(list);
    } else {
      setfilteredUser(data);
    }
  }, [vehicleQuery, vehicleList, data]);

  // edit code
  const [dataArr, setDataArr] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleSubmit = async (e) => {
    dataArr.usertype = "Customer";
    e.preventDefault();
    console.log(data);
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/user/updateCustomer/` +
          dataArr._id,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataArr),
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.status === true) {
        document.getElementById("btnclose").click();
      }
    }

    form.classList.add("was-validated");

    setValidated(true);
  };
  return (
    <>
      <div className="d-flex justify-content-end w-100">
        <input
          className="search-items"
          placeholder="Search Customers by vehicle number"
          onChange={handleVehicleQueryChange}
        />
        <input
          className="search-items"
          placeholder="Search Customers by name"
          onChange={handleQueryChange}
        />
      </div>
      <div className="w-100">
        <DataTable
          pagination
          selectableRowsComponentProps={selectProps}
          dense
          // customStyles={customStyles}
          responsive={true}
          columns={columns}
          data={filteredUser}
          highlightOnHover
          pointerOnHover
        />
      </div>

      <div
        className="modal fade"
        id="edit-customer"
        tabIndex="-1"
        aria-labelledby="edit-customerLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="edit-customerLabel">
                Edit Customer
              </h1>
              <button
                type="button"
                className="btn-close"
                id="btnclose"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
                validated={validated.toString()}
                method="post"
              >
                <div className="mb-3">
                  <input
                    type="name"
                    name="name"
                    value={dataArr.name}
                    onChange={(e) =>
                      setDataArr({ ...dataArr, name: e.target.value })
                    }
                    className="form-control input-field"
                    id="username"
                    placeholder="Enter full name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    value={dataArr.email}
                    onChange={(e) =>
                      setDataArr({ ...dataArr, email: e.target.value })
                    }
                    className="form-control input-field"
                    placeholder="Enter email address"
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control input-field"
                    value={dataArr.phone}
                    onChange={(e) =>
                      setDataArr({ ...dataArr, phone: e.target.value })
                    }
                    placeholder="Enter mobile number"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-success w-100 rounded-pill my-1 py-2"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="view-contact"
        tabIndex="-1"
        aria-labelledby="view-contact-Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="view-contactLabel">
                Emergency Contact
              </h1>
              <button
                type="button"
                className="btn-close"
                id="btnclose"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {contacts.length ? (
                <ul>
                  {contacts.map((item) => (
                    <li key={item.name}>
                      <span>name: {item.name}</span>
                      {", "}
                      <span>phone: {item.phone}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No Contact Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="view-vehicleNo"
        tabIndex="-1"
        aria-labelledby="view-vehicleNo-Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="view-vehicleNoLabel">
                Vehicles Number
              </h1>
              <button
                type="button"
                className="btn-close"
                id="btnclose"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {Vehicles.length ? (
                <ul>
                  {Vehicles.map((item) => (
                    <li key={item._id}>
                      <span>type: {(item.vehicleTypeId || {}).name || ""}</span>
                      {", "}
                      <span>
                        brand: {(item.vehicleBrandId || {}).name || ""}
                      </span>
                      {", "}
                      <span>
                        Model: {(item.vehicleModelId || {}).name || ""}
                      </span>
                      {", "}
                      <span>Number: {item.registrationNumber}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No Vehicle Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTable;
