export default {
  get NODE_ENV() {
    return process.env.NODE_ENV;
  },

  isProd() {
    return this.NODE_ENV === "production";
  },

  isDev() {
    return !this.isProd();
  },

  apiUrl: process.env.REACT_APP_API_URL,
  devApiUrl: process.env.REACT_APP_API_URL_DEV,
};
