import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UilBell } from "@iconscout/react-unicons";
import { UilFileAlt } from "@iconscout/react-unicons";
import { UilTear } from "@iconscout/react-unicons";
import { UilComment } from "@iconscout/react-unicons";
import EmergencyImg from "../../images/emergancy.png";

const Emergency = () => {
  return (
    <>
      <section className="emergancy clearfix">
        <div className="emergancy-left-col">
          <Container>
            <Row>
              <Col xs={12}>
                <h3>Emergency Blood Request</h3>
                <p>
                 SafeWent understands the critical need for blood during emergencies. That's why we have incorporated an Emergency Blood Request feature in our app.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={6} className="gy-5">
                <span className="icon-wrapper">
                  <UilTear />
                </span>
                <h5>Create Request</h5>
                <p>
                 When there is an urgent requirement for blood, users can activate the Emergency Blood Request option in SafeWent. This feature connects them with a network of registered blood donors in their vicinity. 
                </p>
              </Col>
              <Col xs={12} md={6} lg={6} className="gy-5">
                <span className="icon-wrapper">
                  <UilFileAlt />
                </span>
                <h5>Send Notification</h5>
                <p>
                  This feature ensures a quick and efficient response to blood emergencies, potentially saving lives in critical situations. SafeWent is committed to providing a seamless and reliable solution for accessing blood during urgent times of need.
                </p>
              </Col>
              <Col xs={12} md={6} lg={6} className="gy-5">
                <span className="icon-wrapper">
                  <UilComment />
                </span>
                <h5>Get Reply</h5>
                <p>
                 This functionality enables a direct connection between those in need of blood and potential donors, fostering a community-driven approach to blood donation. Users can receive replies from willing donors and coordinate the blood donation process.
                </p>
              </Col>
              <Col xs={12} md={6} lg={6} className="gy-5">
                <span className="icon-wrapper">
                  <UilBell />
                </span>
                <h5>Save Lives</h5>
                <p>
                  By leveraging the power of technology and community support, SafeWent's Emergency Blood Request feature plays a crucial role in saving lives and ensuring timely access to blood donations when it matters most
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="emergancy-right-col">
          <img src={EmergencyImg} alt="" />
        </div>
      </section>
    </>
  );
};

export default Emergency;
