import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Logo from "../../images/logo.png";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const Index = () => {
  const [dataArr, setDataArr] = useState();

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/category`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);
    if (json.status === "Success") {
      setDataArr(json.data);
    }
  };
  // add vehicle type
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    name: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();

      form.classList.add("was-validated");

      setValidated(true);
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/category`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();
      if (json.status === "Success") {
        fetchData();
        setData({ ...data, name: "" });
        setValidated(false);
      }
      console.log(json);
    }
  };

  // delete category
  const handleDeleteCategory = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/category`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: id }),
      }
    );
    const json = await response.json();
    if (json.status === "Success") {
      fetchData();
    }
    console.log(json);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="admin-root1-div">
        <div className="admin-navbar">
          <div className="admin-company">
            <div className="admin-logo">
              <img className="admin-logo-icon" src={Logo} alt="" />
            </div>

            <span className="admin-heading">SAFEWENT</span>
          </div>

          <div className="admin-wrapper">
            <input className="search-bar" type="text" placeholder="Search..." />
            <NotificationsNoneIcon className="noti" />
          </div>
        </div>

        <div className="admin-body">
          <Sidebar index={10} />
          {/* content start  */}
          <div className="maindash-orders">
            <div className=" w-100">
              <div className="heading-customers">Category</div>
            </div>
            <form
              onSubmit={handleSubmit}
              className="needs-validation"
              noValidate
              validated={validated.toString()}
              method="post"
            >
              <div className="d-flex">
                <input
                  className="search-items input-field form-control form-control-lg"
                  name="name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  placeholder="Enter category"
                  required
                />
                <button
                  type="submit"
                  className="btn btn-success rounded-pill px-4"
                >
                  Add
                </button>
              </div>
            </form>
            <div className="row w-100">
              {dataArr &&
                dataArr.map((item) => (
                  <div className="col-3" key={item._id}>
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start mb-3"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="ms-1 me-auto d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onClick={() => handleDeleteCategory(item._id)}
                          defaultChecked
                        />
                        <div className="fw-bold ms-1">{item.name}</div>
                      </div>
                      {/* <span className="badge bg-primary rounded-pill">14</span> */}
                    </li>
                  </div>
                ))}
            </div>
          </div>
          {/* content end  */}
        </div>
      </div>
    </>
  );
};

export default Index;
