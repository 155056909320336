import React from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Sidebar from "../Sidebar/Sidebar";
import Logo from "../../images/logo.png";
import Data from "../Table/AdminTable";

const index = () => {
  return (
    <>
      <div className="admin-root1-div">
        <div className="admin-navbar">
          <div className="admin-company">
            <div className="admin-logo">
              <img className="admin-logo-icon" src={Logo} alt="" />
            </div>

            <span className="admin-heading">SAFEWENT</span>
          </div>

          <div className="admin-wrapper">
            <input className="search-bar" type="text" placeholder="Search..." />
            <NotificationsNoneIcon className="noti" />
          </div>
        </div>

        <div className="admin-body">
          <Sidebar index={1} />
          <Data />
        </div>
      </div>
    </>
  );
};

export default index;
