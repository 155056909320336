import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Logo from "../../images/logo.png";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AddBanner from "../Modal/AddBanner";
import "./Banner.css";

const Banner = () => {
  const [bannerData, setBannerData] = useState([]);
  const fetchAllBanner = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/banner`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    // console.log(json)
    if (json.status === true) {
      setBannerData(json.data);
    }
  };

  const handleDeleteBanner = async (_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/banner`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: _id }),
      }
    );
    const json = await response.json();
    if (json.status === true) {
      fetchAllBanner();
    } else {
      alert(json.message);
    }
  };

  useEffect(() => {
    fetchAllBanner();
  });
  return (
    <>
      <AddBanner />
      <div className="admin-root1-div">
        <div className="admin-navbar">
          <div className="admin-company">
            <div className="admin-logo">
              <img className="admin-logo-icon" src={Logo} alt="" />
            </div>

            <span className="admin-heading">SAFEWENT</span>
          </div>

          <div className="admin-wrapper">
            <input className="search-bar" type="text" placeholder="Search..." />
            <NotificationsNoneIcon className="noti" />
          </div>
        </div>
        <div className="admin-body">
          <Sidebar index={4} />

          <div className="maindash-orders">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="heading-customers">Banner Settings</div>
              <div className="d-flex">
                <button
                  className="btn btn-success rounded-pill"
                  data-bs-toggle="modal"
                  data-bs-target="#add-banner"
                >
                  + Add
                </button>
              </div>
            </div>

            {bannerData &&
              bannerData.map((item, index) => (
                <div className="row w-100" key={index}>
                  <div className="col-2 img-banner">
                    <img
                      src={
                        `${process.env.REACT_APP_API_URL}/api/fetchmedia/` +
                        item.imageUrl
                      }
                      height={125}
                      width={175}
                      alt=""
                    />
                  </div>
                  <div className="col-7">
                    <div className="banner-head">{item.title}</div>
                    <span className="desc">{item.description}</span>
                  </div>
                  <div className="col-3 Dflex">
                    <button
                      type="button"
                      className="btn btn-outline-danger rounded-pill px-3"
                      onClick={() => handleDeleteBanner(item._id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
