import axios from "axios";
import env from "../config/env";

const axiosInstance = axios.create({
  baseURL: env.apiUrl,
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      return Promise.reject(error.response);
    } else if (error.request) {
      return Promise.reject(error.request);
    } else {
      return Promise.reject(error.message);
    }
  }
);

export const apiCaller = async (
  method = "GET",
  endpoint,
  headers,
  body,
  params
) => {
  return axiosInstance({
    method,
    url: endpoint,
    data: body,
    params,
    headers,
  });
};

apiCaller.get = (endpoint, config = {}) => {
  return apiCaller("GET", endpoint, config.headers, config.body, config.params);
};

apiCaller.post = (endpoint, config = {}) => {
  return apiCaller(
    "POST",
    endpoint,
    config.headers,
    config.body,
    config.params
  );
};
