import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import { UilQrcodeScan } from "@iconscout/react-unicons";
import { UilBookOpen } from "@iconscout/react-unicons";
import { UilBell } from "@iconscout/react-unicons";
import { UilFileAlt } from "@iconscout/react-unicons";
import { UilMedicalDrip } from "@iconscout/react-unicons";
import PhoneGrp from "../../images/phone-grp.png";

const Safety = () => {
  return (
    <>
      <section className="safety">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h2>Safewent Features</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4} className="text-center gy-5">
              <span className="icon-wrapper mx-auto">
                <UilMedicalDrip />
              </span>
              <h5>Blood Bank</h5>
              <p>Locate & Get Blood during medical emergencies.</p>
            </Col>
            <Col xs={12} md={6} lg={4} className="text-center gy-5">
              <span className="icon-wrapper mx-auto">
                <UilExclamationTriangle />
              </span>
              <h5>Parking Alert</h5>
              <p>Set reminders to avoid parking violations and tickets.</p>
            </Col>
            <Col xs={12} md={6} lg={4} className="text-center gy-5">
              <span className="icon-wrapper mx-auto">
                <UilQrcodeScan />
              </span>
              <h5>QR tags</h5>
              <p>Easily allow SOS notifications in case of emergencies</p>
            </Col>
            <Col xs={12} md={6} lg={4} className="text-center gy-5">
              <span className="icon-wrapper mx-auto">
                <UilBookOpen />
              </span>
              <h5>Group Tracking</h5>
              <p>
                Track the real-time location of friends and family for added
                safety.
              </p>
            </Col>
            <Col xs={12} md={6} lg={4} className="text-center gy-5">
              <span className="icon-wrapper mx-auto">
                <UilBell />
              </span>
              <h5>Accident Alert</h5>
              <p>
                Instantly notify emergency contacts in case of accidents or
                emergencies.
              </p>
            </Col>
            <Col xs={12} md={6} lg={4} className="text-center gy-5">
              <span className="icon-wrapper mx-auto">
                <UilFileAlt />
              </span>
              <h5>Store Documents</h5>
              <p>Digitally store and manage essential documents.</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-5 text-center">
              <img className="safety-sec-img" src={PhoneGrp} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Safety;
