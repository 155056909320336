import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UilMobileAndroid } from "@iconscout/react-unicons";
import { UilEnvelope } from "@iconscout/react-unicons";
import { UilFacebook } from "@iconscout/react-unicons";
import { UilTwitter } from "@iconscout/react-unicons";
import { UilInstagram } from "@iconscout/react-unicons";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container>
          <ul className="d-flex align-items-center justify-content-center flex-wrap">
            <li>
              <a href="#">Contact us</a>
            </li>
            <li>
              <a href="#">About us</a>
            </li>
            <li>
              <a href="#">Privacy policy</a>
            </li>
            <li>
              <a href="#">Terms and conditions</a>
            </li>
            <li>
              <a href="#">Refund policy</a>
            </li>
          </ul>
          <Row className="gx-4 gy-4 gy-md-0 justify-content-center">
            {/* <Col xs={12} md={6} lg={3} className="text-center">
              <span className="icon-wrapper" style={{
                borderRadius: '50%',
                backgroundColor: '#fff',
                color: "#2B6334",
                margin: '0 auto 1rem'
              }}>
                <UilMapMarker />
              </span>
              <h5>India Location</h5>
              <p>Bangalore</p>
            </Col> */}
            {/* <Col xs={12} md={6} lg={3} className="text-center">
              <span className="icon-wrapper" style={{
                borderRadius: '50%',
                backgroundColor: '#fff',
                color: "#2B6334",
                margin: '0 auto 1rem'
              }}>
                <UilPhone />
              </span>
              <h5>Telephone</h5>
              <p><a href="tel:+91 123 456 7890">+91 123 456 7890</a></p>
            </Col> */}
            <Col xs={12} md={4} className="text-center">
              <span
                className="icon-wrapper"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  color: "#2B6334",
                  margin: "0 auto 1rem",
                }}
              >
                <UilMobileAndroid />
              </span>
              <h5>Mobile</h5>
              <p>
                <a href="tel:+91 89029 83066">+91 89029 83066</a>
              </p>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <span
                className="icon-wrapper"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  color: "#2B6334",
                  margin: "0 auto 1rem",
                }}
              >
                <UilEnvelope />
              </span>
              <h5>Email Address</h5>
              <p>
                <a href="mailto:info@safewent.com">info@safewent.com</a>
              </p>
            </Col>
          </Row>
          <Row className="gx-4">
            <Col xs={12} className="mt-5 text-center">
              <p>
                &copy; 2022 Safewent Private Limited, All Rights Reserved{" "}
                <a
                  href="https://www.facebook.com/safewent?mibextid=ZbWKwL"
                  className="ms-1"
                >
                  <UilFacebook />
                </a>
                <a
                  href="https://twitter.com/safewent?t=mFQ1iJbCO_53F21zE8r8TQ&s=08"
                  className="ms-1"
                >
                  <UilTwitter />
                </a>
                <a
                  href="https://instagram.com/safewent?igshid=YmM0MjE2YWMzOA=="
                  className="ms-1"
                >
                  <UilInstagram />
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
