import React from 'react'
// import { CardData } from '../../Data/Data'
// import Card from '../Card/Card'
import './Cards.css'
import OrderImg from '../../images/Order.png'
import TimeImg from '../../images/time.png'
import TruckImg from '../../images/Truck.png'
import VectorImg from '../../images/Vector.png'

const Cards = (props) => {

    const CardData = [
        {
            png: OrderImg,
            count: props.total,
            title: 'Total Orders',
            color: '#2B6334'
        },
        {
            png: TimeImg,
            count: props.pending,
            title: 'Pending Orders',
            color: '#efb20e'
        },
        {
            png: TruckImg,
            count: props.Shipped,
            title: 'Shipping Orders',
            color: '#5d5fef'
        },
        {
            png: VectorImg,
            count: props.cencel,
            title: 'Cancel Orders',
            color: '#f94d32'
        }
    ]
    console.log(props)
    return (
        <div className='main-Cards'>
            {
                CardData.map((card, index) => {
                    return (
                        <div className='parentContainer'>
                            {/* <Card
                                png={array[index]}
                                count={card.count}
                                title={card.title}
                                color={card.color}
                                orders={props.total}
                            /> */}
                            <div className='Card'>
                                <div className='cardImage' style={{backgroundColor: card.color}}>

                                    <img src={card.png} alt='' />

                                </div>
                                <div className='cardData'>
                                    <div className='cardCount'>{card.count} </div>
                                    <div className='cardTitle'>{card.title} </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Cards
