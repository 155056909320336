import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Logo from "../../images/logo.png";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const ReasonSetting = () => {
  const [reasonData, setReasonData] = useState([]);
  const [refresh, setRefresh] = useState("false");
  const fetchReasons = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/Reasons`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    console.log(json);
    if (json.status === true) {
      setReasonData(json.data);
    }
  };

  const [validated, setValidated] = useState(false);
  const [inputData, setInputData] = useState({
    _id: "",
    reasonText: "",
    notificationText: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
      setValidated(true);
      e.stopPropagation();
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Reason`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData),
        }
      );
      const json = await response.json();
      console.log(json);
      if (json.status === true) {
        document.getElementById("btn-close").click();
        fetchReasons();
        setInputData({ ...inputData, reasonText: "", notificationText: "" });
      }
    }
  };

  const handleDeleteReasin = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/reason`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: id }),
      }
    );
    const json = await response.json();
    if (json.status === true) {
      fetchReasons();
    }
    console.log(json);
  };

  const handleStatus = async (reasonId, status) => {
    try {
      const response = await fetch(
        
        `${process.env.REACT_APP_API_URL}/api/reason/update/${reasonId}`,
        // `http://localhost:8000/api/reason/update/${reasonId}`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({status}),
        }
      );
      const json = await response.json();
      // console.log(json);

      if(json.success){
        setRefresh(!refresh)
      }

    } catch (error) {
      console.log(error)

    }


  }

  useEffect(() => {
    fetchReasons();
  }, [refresh]);


  return (
    <>
      {/* <AddBanner /> */}
      <div className="admin-root1-div">
        <div className="admin-navbar">
          <div className="admin-company">
            <div className="admin-logo">
              <img className="admin-logo-icon" src={Logo} alt="" />
            </div>

            <span className="admin-heading">SAFEWENT</span>
          </div>

          <div className="admin-wrapper">
            <input className="search-bar" type="text" placeholder="Search..." />
            <NotificationsNoneIcon className="noti" />
          </div>
        </div>
        <div className="admin-body">
          <Sidebar index={11} />

          <div className="maindash-orders">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="heading-customers">Reasons</div>
              <div className="d-flex">
                <button
                  className="btn btn-success rounded-pill"
                  data-bs-toggle="modal"
                  data-bs-target="#add-reason"
                >
                  + Add
                </button>
              </div>
            </div>
            <div className="row w-100">
              {reasonData &&
                reasonData.map((item, index) => (
                  <div className="col-6" key={item._id} >
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start mb-3"
                      style={{ borderRadius: "10px", backgroundColor: item.status === "active" ? "#DDE6DE" : "white", }}
                    >
                      <div className="ms-1 me-auto d-flex justify-between" >

                        <div className="ms-1 me-auto d-flex ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => handleDeleteReasin(item._id)}
                            defaultChecked
                          />
                          <div className="fw-bold ms-2">{item.reasonText}</div>

                        </div>

                        {
                          item.status === "active" ? (

                            <button className="text-sm" style={{ outline: "none", border: '1px solid gray', borderRadius: "5px", backgroundColor: "transparent", marginLeft: "220px", fontSize: "14px" }} onClick={e => handleStatus(item._id, "inactive")} >Inactive</button>
                          ) : (

                            <button className="text-sm" style={{ outline: "none", border: '1px solid gray', borderRadius: "5px", backgroundColor: "transparent", marginLeft: "220px", fontSize: "14px" }} onClick={e => handleStatus(item._id, "active")}>Active</button>
                          )
                        }

                      </div>
                      {/* <span className="badge bg-primary rounded-pill">14</span> */}
                    </li>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* modal  */}
      <div
        className="modal fade"
        id="add-reason"
        tabIndex="-1"
        aria-labelledby="add-reasonLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="add-reasonLabel">
                Add Reason
              </h1>
              <button
                type="button"
                className="btn-close"
                id="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
                validated={validated.toString()}
                method="post"
              >
                <div className="mb-2">
                  <label
                    htmlFor="username"
                    className="form-label text-gray3 text-12-400"
                  >
                    Reason text
                  </label>
                  <input
                    type="name"
                    name="reasonText"
                    value={inputData.reasonText}
                    onChange={(e) =>
                      setInputData({ ...inputData, reasonText: e.target.value })
                    }
                    className="form-control input-field"
                    id="username"
                    placeholder="Enter reason text"
                    required
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="desc"
                    className="form-label text-gray3 text-12-400"
                  >
                    Notification text
                  </label>
                  <textarea
                    className="form-control input-field"
                    name="notificationText"
                    value={inputData.notificationText}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        notificationText: e.target.value,
                      })
                    }
                    id="desc"
                    rows="3"
                    placeholder="Enter notification text"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-success w-100 rounded-pill my-3"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReasonSetting;
