import React, { useState } from 'react'
import './Login.css'
import { Link } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import { useEffect } from 'react';

const SignUp = () => {

    const [phone, setPhone] = useState("+91");
    const [validated, setValidated] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.preventDefault()
            e.stopPropagation()
        } else {

            // const response = await fetch("http://localhost:8001/api/user/otp-verify", {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({ otp: otp, phone: "919084297757" })
            // });
            // if (response.status === true) {
            //     history("/otp-verify");
            // } else {
            //     // alert(response.message);
            //     showAlert(response.message, "danger");
            // }
        }
        form.classList.add('was-validated')

        setValidated(true)
    }

    // const getBloodGroups = async () => {
    //     const response = await fetch("http://localhost:8001/api/bloodrequest", {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2Y3NWRmNWEyNDliM2NiNDJiYjFmYTQiLCJyb2xlIjoiQWRtaW4iLCJpYXQiOjE2NzcxNTU4MjksImV4cCI6MTY3Nzc2MDYyOX0.JYKTzMAPFCWKrHIXlCvAszQqBbFwPkxDY4YyXCMs9Ls',
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         console.log(response)
    // }

    // useEffect(() =>{
    //     getBloodGroups()
    // })

    return (
        <>
            <section className="vh-100">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <h2 className="text-center">Sign Up</h2>
                            <p className='text-center text-center text-14-500'>Welcome to Safewent</p>
                            <form className='needs-validation' noValidate onSubmit={handleSubmit} validated={validated.toString()} method='post'>
                                <div className="form-outline mb-2">
                                    <label className="form-label text-gray3 text-12-400">Phone Number</label>
                                    <PhoneInput
                                        country={"eg"}
                                        enableSearch={true}
                                        value={phone}
                                        minLength={10}
                                        onChange={(phone) => setPhone(phone)}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="exampleFormControlInput1" className="form-label text-gray3 text-12-400">User Name</label>
                                    <input type="text" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Enter your full name" required />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label text-gray3 text-12-400">Email Address</label>
                                    <input type="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Enter your email address" required
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="exampleFormControlInput1" className="form-label text-gray3 text-12-400">Select Blood Group</label>
                                    <select className="form-select select-input form-control" aria-label="Default select example" required>
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <button className="btn btn-success btn-lg rounded-pill w-100" type="submit">Sent OTP</button>
                            </form>
                            <p className='text-center text-muted mt-3'>Have an Account <Link to={'/sign-in'} className="signup-link">Sign In</Link></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUp