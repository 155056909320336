import React, { useEffect, useState } from "react";
import Select from "react-select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Table.css";
import axios from "../../helpers/axios";
import Product from "../../images/product.png";
import Cards from "../Cards/Cards";
import { Link } from "react-router-dom";
import makeAnimated from "react-select/animated";
import env from "../../config/env";
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const OrderTable = () => {
  const animatedComponents = makeAnimated();

  const [type, setType] = useState("");

  const handleEditOrder = (item) => {
    console.log(item);
    setFormData(item);
    setType("Edit");
    formData.orderId = item._id;
    console.log(item);
  };
  const [orderData, setOrderData] = useState([]);
  const getProducts = async (e) => {
    const res = await axios.get(`${env.apiUrl}/api/products`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    setOrderData(res.data.data);
  };

  const transformed = orderData.map(({ _id, name }) => ({
    label: name,
    value: _id,
  }));
  const [data, setData] = useState([]);
  const getOrders = async (e) => {
    const res = await axios.get(`${env.apiUrl}/api/orders`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    setData(res.data.data);
    console.log({ ordersResponse: res });
  };
  const cancel = data.filter(function(e) {
    return e.status === "Cancelled" || e.status === "Returned";
  }).length;
  const pending = data.filter(function(e) {
    return e.status === "Created";
  }).length;
  const Shipped = data.filter(function(e) {
    return e.status === "Shipped";
  }).length;

  const [user, setUser] = useState([]);
  const getUsers = async (e) => {
    const res = await axios.get(`${env.apiUrl}/api/user/getUser`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    setUser(res.data.data);
  };
  const [formData, setFormData] = useState({
    product: [],
    productList: [],
    orderAmount: "",
    shippingAddressId: "",
    ordernumber: "",
    customerId: "",
  });

  const handleAddOrder = () => {
    setFormData({
      product: [],
      productList: [],
      orderAmount: "",
      shippingAddressId: "",
      ordernumber: "",
      customerId: "",
    });
    setType("Add");
  };

  const [address, setAddress] = useState([]);
  const getAddress = async (userid) => {
    setFormData({ ...formData, customerId: userid });
    const res = await axios.get(`${env.apiUrl}/api/address`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    setAddress(res.data.data);
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      formData.productList = [];
      for (let i = 0; i < formData.productList.length; i++) {
        formData.productList.push(formData.productList[i].value);
      }
      console.log(formData);
      const response = await fetch(`${env.apiUrl}/api/order`, {
        method: type === "Edit" ? "PUT" : "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      if (json.status === true) {
        document.getElementById("btnclose").click();
      }
    }
    form.classList.add("was-validated");

    setValidated(true);
  };

  const makeStyle = (status) => {
    if (status === "Delivered") {
      return {
        background: "rgb(145 254 159 / 47%)",
        color: "green",
        padding: "8px 12px",
      };
    } else if (status === "Shipped") {
      return {
        color: "#EFB20E",
        padding: "8px 12px",
        background: "#FDF7E7",
      };
    } else if (status === "Created") {
      return {
        color: "#EFB20E",
        padding: "8px 12px",
        background: "#FDF7E7",
      };
    } else if (status === "Patched") {
      return {
        color: "#EFB20E",
        padding: "8px 12px",
        background: "#FDF7E7",
      };
    } else if (status === "Cancelled") {
      return {
        background: "#FBEBE9",
        color: "#F94D32",
        padding: "8px 12px",
      };
    }
  };
  useEffect(() => {
    getOrders();
    getProducts();
    getUsers();
    getAddress();
  }, []);
  return (
    <>
      <div className="maindash-orders">
        <div className=" w-100 d-flex align-items-center justify-content-between">
          <div className="heading-customers">Orders</div>
          <div className="d-flex">
            <input className="search-items" placeholder="Search Customer.." />
            <button
              className="btn btn-success rounded-pill"
              onClick={() => handleAddOrder()}
              data-bs-toggle="modal"
              data-bs-target="#add-order"
            >
              + Add
            </button>
          </div>
        </div>

        <Cards
          total={data.length}
          cencel={cancel}
          pending={pending}
          Shipped={Shipped}
          className="maindash-Cards"
        />

        <div className="table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Order Number</TableCell>
                  <TableCell align="right">Product</TableCell>
                  <TableCell align="right">Order Date</TableCell>
                  <TableCell align="right">Delivery Date</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <TableRow
                      key={index}
                      className="table-rows"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className="onumber "
                        component="th"
                        scope="row"
                      >
                        <span className="childTitle">{row.ordernumber}</span>
                      </TableCell>
                      <TableCell className="product" align="right">
                        <div className="parentItem">
                          <div className="childImage-div">
                            <img className="childImage" src={Product} alt="" />
                          </div>
                          <div className="childTitle d-flex flex-column">
                            {row.productList &&
                              row.productList.map((item) => (
                                <span className="mb-1">{item.name}</span>
                              ))}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="odate" align="right">
                        <span className="childTitle">
                          {dayjs(row.createdAt).format("DD/MM/YYYY")}
                        </span>
                      </TableCell>
                      <TableCell className="ddate" align="right">
                        <span className="childTitle">
                          {row.actualDeliveryDate}
                        </span>
                      </TableCell>
                      <TableCell className="ostatus" align="right">
                        <span className="status" style={makeStyle(row.status)}>
                          {row.status}
                        </span>
                      </TableCell>
                      <TableCell className="childTitle" align="right">
                        {row.orderAmount}
                      </TableCell>
                      <TableCell className="childTitle" align="right">
                        <i
                          className="bi bi-three-dots btn"
                          data-bs-toggle="dropdown"
                        ></i>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              to={"/admin-dashboard-products"}
                              state={row}
                              className="dropdown-item"
                            >
                              <i className="bi bi-eye-fill"></i> view
                            </Link>
                          </li>
                          <li>
                            <span
                              className="dropdown-item btn"
                              onClick={() => handleEditOrder(row)}
                              data-bs-toggle="modal"
                              data-bs-target="#add-order"
                            >
                              <i className="bi bi-pencil-fill"></i> edit
                            </span>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div
        className="modal fade"
        id="add-order"
        tabIndex="-1"
        aria-labelledby="add-orderLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="add-orderLabel">
                {type} Order
              </h1>
              <button
                type="button"
                className="btn-close"
                id="btnclose"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                noValidate
                validated={validated.toString()}
                method="post"
              >
                <div className="mb-3">
                  <select
                    className="form-select input-field"
                    name="customerId"
                    value={formData.customerId}
                    onChange={(e) => getAddress(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Customer
                    </option>
                    {user &&
                      user.map((ut, i) => (
                        <option key={i} value={ut._id}>
                          {ut.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <Select
                    defaultValue={[]}
                    isMulti
                    // value={formData.productList}
                    name="productList"
                    components={animatedComponents}
                    options={transformed}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => setFormData({ ...formData, product: e })}
                  />
                </div>
                <div className="mb-3">
                  <select
                    className="form-select input-field"
                    name="shippingAddressId"
                    value={formData.shippingAddressId}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        shippingAddressId: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select Delevery Address
                    </option>
                    {address &&
                      address.map((item, i) => (
                        <option key={i} value={item._id}>
                          {item.addressName}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <input
                    type="date"
                    class="form-control"
                    name="deliveryDate"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        deliveryDate: dayjs(
                          e.target.value,
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY"),
                      })
                    }
                  />
                </div>
                {type === "Edit" && (
                  <select
                    className="form-select input-field"
                    name="shippingAddressId"
                    value={formData.status}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        status: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select Status
                    </option>
                    {[
                      "Created",
                      "Shipped",
                      "Cancelled",
                      "Delivered",
                      "Returned",
                    ].map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                )}
                <button
                  type="submit"
                  className="btn btn-success w-100 rounded-pill my-2 py-2"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderTable;
