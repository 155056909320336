import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import './Table.css'
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

export const CustomerTable = () => {

    const [permission, setPermission] = useState({});
    const handleEditCustomer = async (item) => {
        setDataArr(item)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Permission/` + item._id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json()
        if (json.status === true) {
            setPermission(json.data)
        }
    }
    // const addNewRecord = () => {
    //     setDataArr({ name: '', email: '', phone: '', usertype: 'Admin' })
    // }
    const [validated, setValidated] = useState(false)

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Mobile',
            selector: row => row.phone,
            sortable: true,
            width: '15%',
        }, {
            name: 'Email',
            selector: row => row.email,
            width: '30%',
        }, {
            name: 'Address',
            selector: row => row.createdAt,
            width: '25%',
        },
        {
            name: 'ACTION',
            selector: row =>
                <div className='d-flex align-items-center justify-content-around'>
                    <i className="bi bi-person-gear tbl_icon" onClick={() => handleEditCustomer(row)} data-bs-toggle="modal" data-bs-target="#add-permission"></i>
                </div>
            ,
            center: true
        },
    ];

    const handleChange = e => {
        const { name, checked } = e.target;
        setPermission({
            ...permission,
            [name]: checked
        })
    }

    const [data, setData] = useState();
    const getCustomers = async (e) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getUser`,
            {
                method: 'GET', 
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            });
            const json = await res.json()
        setData(json.data.filter(function (e) { return e.usertype === 'Admin' }))
    }
    
    useEffect(() => {
        getCustomers()
    }, []);

    // edit code 
    const [dataArr, setDataArr] = useState({
        name: '',
        email: '',
        phone: '',
        usertype: 'Admin'
    })

    const handleSubmit = async (e) => {
        permission.userid = dataArr._id;
        e.preventDefault();
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Permission/`,permission._id, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(permission)
            });
            const json = await response.json()
            console.log(json)
            if (json.status === true) {
                document.getElementById('btnclose').click()
            }
        }

        form.classList.add('was-validated')

        setValidated(true)
    }
    return (
        <>
            <div className='maindash-orders'>
                <div className=' w-100 d-flex align-items-center justify-content-between'>
                    <div className='heading-customers'>Permissions</div>
                    <div className='d-flex'>
                        <input className='search-items' placeholder='Search user..' />
                        {/* <button className='btn btn-success rounded-pill' onClick={() => addNewRecord()} data-bs-toggle="modal" data-bs-target="#add-permission">Permission To User</button> */}
                    </div>
                </div>
                <div className='w-100'>
                    <DataTable pagination selectableRowsComponentProps={selectProps} dense
                        responsive={true} columns={columns} data={data} highlightOnHover
                        pointerOnHover  // customStyles={customStyles}
                    />
                </div>
            </div>


            <div className="modal fade" id="add-permission" tabIndex="-1" aria-labelledby="add-permissionLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="add-permissionLabel">Give Permission To</h1>
                            <button type="button" className="btn-close" id='btnclose' data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} className='needs-validation' noValidate validated={validated.toString()} method="post">
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Orders</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="createNewOrder">
                                                Create New Orders
                                            </label>
                                            <input className="form-check-input" name='createNewOrder' type="checkbox" id="createNewOrder" onChange={handleChange} defaultChecked={permission.createNewOrder === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editOrder">
                                                Edit Orders
                                            </label>
                                            <input className="form-check-input" name='editOrder' type="checkbox" onChange={handleChange} id="editOrder" defaultChecked={permission.editOrder === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewOrder">
                                                View Orders
                                            </label>
                                            <input className="form-check-input" name='viewOrder' type="checkbox" onChange={handleChange} id="viewOrder"  defaultChecked={permission.viewOrder === true?true:false} />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Customers</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="createNewCustomer">
                                                Create New Customer
                                            </label>
                                            <input className="form-check-input" name='createNewCustomer' type="checkbox" id="createNewCustomer" defaultChecked={permission.createNewCustomer === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editCustomer">
                                                Edit Customer
                                            </label>
                                            <input className="form-check-input" name='editCustomer' type="checkbox" id="editCustomer" defaultChecked={permission.editCustomer === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewCustomer">
                                                View Customer
                                            </label>
                                            <input className="form-check-input" name='viewCustomer' type="checkbox" onChange={handleChange} id="viewCustomer" defaultChecked={permission.viewCustomer === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteCustomer">
                                                Delete Customer
                                            </label>
                                            <input className="form-check-input" name='deleteCustomer' type="checkbox" onChange={handleChange} id="deleteCustomer" defaultChecked={permission.deleteCustomer === true?true:false} />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Products</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="createNewProduct">
                                                Create New Product
                                            </label>
                                            <input className="form-check-input" name='createNewProduct' type="checkbox" onChange={handleChange} id="createNewProduct" defaultChecked={permission.createNewProduct === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editProduct">
                                                Edit Product
                                            </label>
                                            <input className="form-check-input" name='editProduct' type="checkbox" onChange={handleChange} id="editProduct" defaultChecked={permission.editProduct === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewProduct">
                                                View Product
                                            </label>
                                            <input className="form-check-input" name='viewProduct' type="checkbox" onChange={handleChange} id="viewProduct" defaultChecked={permission.viewProduct === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteProduct">
                                                Delete Product
                                            </label>    
                                            <input className="form-check-input" name='deleteProduct' type="checkbox" onChange={handleChange} id="deleteProduct" defaultChecked={permission.deleteProduct === true?true:false} />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Reviews</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="createNewReview">
                                                Create New Review
                                            </label>
                                            <input className="form-check-input" name='createNewReview' type="checkbox" onChange={handleChange} id="createNewReview" defaultChecked={permission.createNewReview === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewReview">
                                                View Review
                                            </label>
                                            <input className="form-check-input" name='viewReview' type="checkbox" onChange={handleChange} id="viewReview" defaultChecked={permission.viewReview === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteReview">
                                                Delete Review
                                            </label>    
                                            <input className="form-check-input" name='deleteReview' type="checkbox" onChange={handleChange} id="deleteReview" defaultChecked={permission.deleteReview === true?true:false} />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Banners</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="createBanner">
                                                Create New Banner
                                            </label>
                                            <input className="form-check-input" name='createBanner' type="checkbox" onChange={handleChange} id="createBanner" defaultChecked={permission.createBanner === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewBanner">
                                                View Banner
                                            </label>
                                            <input className="form-check-input" name='viewBanner' type="checkbox" onChange={handleChange} id="viewBanner" defaultChecked={permission.viewBanner === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteBanner">
                                                Delete Banner
                                            </label>    
                                            <input className="form-check-input" name='deleteBanner' type="checkbox" onChange={handleChange} id="deleteBanner" defaultChecked={permission.deleteBanner === true?true:false} />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Vehicle Type</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="addVehicletype">
                                                Add Vehicle Type
                                            </label>
                                            <input className="form-check-input" name='addVehicletype' type="checkbox" onChange={handleChange} id="addVehicletype" defaultChecked={permission.addVehicletype === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewVehicletype">
                                                View Vehicle Type
                                            </label>
                                            <input className="form-check-input" name='viewVehicletype' type="checkbox" onChange={handleChange} id="viewVehicletype" defaultChecked={permission.viewVehicletype === true?true:false} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteVehicletype">
                                                Delete Vehicle Type
                                            </label>    
                                            <input className="form-check-input" name='deleteVehicletype' type="checkbox" onChange={handleChange} id="deleteVehicletype" defaultChecked={permission.deleteVehicletype === true?true:false} />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Vehicle Type</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="addVehiclebrand">
                                                Add Vehicle brand
                                            </label>
                                            <input className="form-check-input" name='addVehiclebrand' type="checkbox" defaultChecked={permission.addVehiclebrand === true?true:false}  onChange={handleChange} id="addVehiclebrand"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewVehiclebrand">
                                                View Vehicle brand
                                            </label>
                                            <input className="form-check-input" name='viewVehiclebrand' type="checkbox" defaultChecked={permission.viewVehiclebrand === true?true:false}  onChange={handleChange} id="viewVehiclebrand"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteVehiclebrand">
                                                Delete Vehicle brand
                                            </label>    
                                            <input className="form-check-input" name='deleteVehiclebrand' type="checkbox" defaultChecked={permission.deleteVehiclebrand === true?true:false}  onChange={handleChange} id="deleteVehiclebrand" />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Vehicle Model</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="addVehiclemodel">
                                                Add Vehicle model
                                            </label>
                                            <input className="form-check-input" name='addVehiclemodel' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="addVehiclemodel"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewVehiclemodel">
                                                View Vehicle model
                                            </label>
                                            <input className="form-check-input" name='viewVehiclemodel' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewVehiclemodel"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteVehiclemodel">
                                                Delete Vehicle model
                                            </label>    
                                            <input className="form-check-input" name='deleteVehiclemodel' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="deleteVehiclemodel"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Reasons</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="addReason">
                                                Add Reason
                                            </label>
                                            <input className="form-check-input" name='addReason' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="addReason"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewReason">
                                                View Reason
                                            </label>
                                            <input className="form-check-input" name='viewReason' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewReason"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteReason">
                                                Delete Reason
                                            </label>    
                                            <input className="form-check-input" name='deleteReason' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="deleteReason"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">FAQs</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="addFAQs">
                                                Add FAQs
                                            </label>
                                            <input className="form-check-input" name='addFAQs' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="addFAQs"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewFAQs">
                                                View FAQs
                                            </label>
                                            <input className="form-check-input" name='viewFAQs' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewFAQs"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteFAQs">
                                                Delete FAQs
                                            </label>    
                                            <input className="form-check-input" name='deleteFAQs' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="deleteFAQs"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">QR Code Templete</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editTemplete">
                                                Edit Templete
                                            </label>
                                            <input className="form-check-input" name='editTemplete' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editTemplete"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewTemplete">
                                                View Templete
                                            </label>
                                            <input className="form-check-input" name='viewTemplete' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewTemplete"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Notifications</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="addCampaigns">
                                                Add Campaign
                                            </label>
                                            <input className="form-check-input" name='addCampaigns' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="addCampaigns"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewCampaigns">
                                                View Campaign
                                            </label>
                                            <input className="form-check-input" name='viewCampaigns' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewCampaigns"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteCampaigns">
                                                Delete Campaign
                                            </label>    
                                            <input className="form-check-input" name='deleteCampaigns' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="deleteCampaigns"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Admins</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="createNewAdmin">
                                                Add Admin
                                            </label>
                                            <input className="form-check-input" name='createNewAdmin' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="createNewAdmin"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editAdmin">
                                                Edit Admin
                                            </label>
                                            <input className="form-check-input" name='editAdmin' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editAdmin"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewAdmin">
                                                View Admin
                                            </label>
                                            <input className="form-check-input" name='viewAdmin' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewAdmin"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteAdmin">
                                                Delete Admin
                                            </label>    
                                            <input className="form-check-input" name='deleteAdmin' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="deleteAdmin"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Website</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editContactUs">
                                                Edit Contact Us
                                            </label>
                                            <input className="form-check-input" name='editContactUs' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editContactUs"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editAboutUs">
                                                Edit About Us
                                            </label>
                                            <input className="form-check-input" name='editAboutUs' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editAboutUs"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editPrivacyPolicy">
                                                Edit Privacy Policy
                                            </label>
                                            <input className="form-check-input" name='editPrivacyPolicy' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editPrivacyPolicy"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editTermsCondition">
                                                Edit Terms & Conditions
                                            </label>    
                                            <input className="form-check-input" name='editTermsCondition' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editTermsCondition"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editShippingPolicy">
                                                Edit Shipping Policy
                                            </label>    
                                            <input className="form-check-input" name='editShippingPolicy' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editShippingPolicy"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editReturnedPolicy">
                                                Edit Refuned Policy
                                            </label>    
                                            <input className="form-check-input" name='editReturnedPolicy' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editReturnedPolicy"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Coupon Code</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="addCouponCode">
                                                Add Coupon
                                            </label>
                                            <input className="form-check-input" name='addCouponCode' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="addCouponCode"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editCouponCode">
                                                Edit Coupon
                                            </label>
                                            <input className="form-check-input" name='editCouponCode' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editCouponCode"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewCouponCode">
                                                View Coupon
                                            </label>
                                            <input className="form-check-input" name='viewCouponCode' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewCouponCode"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="deleteCouponCode">
                                                Delete Coupon
                                            </label>    
                                            <input className="form-check-input" name='deleteCouponCode' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="deleteCouponCode"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">SOS Call</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="editSosCall">
                                                Edit Coupon
                                            </label>
                                            <input className="form-check-input" name='editSosCall' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="editSosCall"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewSosCall">
                                                Edit Coupon
                                            </label>
                                            <input className="form-check-input" name='viewSosCall' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewSosCall"  />
                                        </div>
                                    </li>
                                </ul>
                                <ul className="list-group">
                                    <li className="list-group-item permission-head">Print QR Code</li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="printQRCode">printQRCode</label>
                                            <input className="form-check-input" name='printQRCode' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="printQRCode"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewCart">
                                                View Cart
                                            </label>
                                            <input className="form-check-input" name='viewCart' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewCart"  />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label className="form-check-label" htmlFor="viewQueries">
                                                View Queries
                                            </label>
                                            <input className="form-check-input" name='viewQueries' type="checkbox" defaultChecked={permission.deleteVehicletype === true?true:false}  onChange={handleChange} id="viewQueries"  />
                                        </div>
                                    </li>
                                </ul>
                                <button type="submit" className="btn btn-success w-100 rounded-pill my-3">Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerTable