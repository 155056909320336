import React, { useState } from 'react'
import './Modal.css';

const AddBanner = () => {
    const [validated, setValidated] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(e.currentTarget);
        // for(var key of formData.entries()){
        //     console.log(key);
        // }
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/banner`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: formData
            });
            const json = await response.json()
            if (json.status === true) {
                document.getElementById('btn-close').click();
            }
        }

        form.classList.add('was-validated')

        setValidated(true)
    }
    return (
        <div className="modal fade" id="add-banner" tabIndex="-1" aria-labelledby="add-bannerLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="add-bannerLabel">Add Banner</h1>
                        <button type="button" className="btn-close" id='btn-close' data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit} className='needs-validation' noValidate validated={validated.toString()} method="post" encType="multipart/form-data">
                            <div className="mb-2">
                                <label htmlFor="username" className="form-label text-gray3 text-12-400">Title</label>
                                <input type="name" name='title' className="form-control input-field" id="username" placeholder="Enter title" required />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="formFile" className="form-label text-gray3 text-12-400">Banner Image</label>
                                <input className="form-control input-field" type="file" name='imageUrl' id="formFile" required />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="desc" className="form-label text-gray3 text-12-400">Description</label>
                                <textarea className="form-control input-field" name='description' id="desc" rows="3" required></textarea>
                            </div>
                            <button type="submit" className="btn btn-success w-100 rounded-pill my-3">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBanner