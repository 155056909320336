import React from 'react'
import Cards from '../Cards/Cards'
import Table from '../Table/Table'
import './MainDash.css'

const MainDash = () => {
    return (
        <div className='maindash-orders' >
            <div className=' w-100 d-flex align-items-center justify-content-between'>
                <div className='heading-customers'>Orders</div>
                <div className='d-flex'>
                    <input className='search-items' placeholder='Search Customer..' />
                    <button className='btn btn-success rounded-pill' data-bs-toggle="modal" data-bs-target="#addCustomerModal">+ Add</button>
                </div>
            </div>

            <Cards className='maindash-Cards' />
            <Table />
        </div>
    )
}

export default MainDash
