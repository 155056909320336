import PersonIcon from "@mui/icons-material/Person";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import StoreIcon from "@mui/icons-material/Store";
import SettingsIcon from "@mui/icons-material/Settings";
import QrCodeIcon from "@mui/icons-material/QrCode";
import GppGoodIcon from "@mui/icons-material/GppGood";

export const SidebarData = [
  {
    icon: FolderCopyIcon,
    heading: "Orders",
    path: "admin-dashboard",
  },
  {
    icon: PersonIcon,
    heading: "Users",
    path: "admin",
  },
  {
    icon: StoreIcon,
    heading: "Shops",
    path: "admin-shop",
  },
  {
    icon: PersonIcon,
    heading: "Customers",
    path: "admin-customers",
  },
  {
    icon: SettingsIcon,
    heading: "Settings",
    subheadings: [
      { id: 1, name: "Banner", path: "admin-banner" },
      { id: 1, name: "Vehicle type", path: "admin-vehicle-type" },
      { id: 1, name: "Vehicle Brands", path: "admin-vehicle-brand" },
      { id: 1, name: "Vehicle Model", path: "admin-vehicle-model" },
      { id: 1, name: "Reason", path: "admin-reason" },
      { id: 1, name: "FAQs", path: "admin-faqs" },
    ],
    path: "admin-banner",
  },
  {
    icon: GppGoodIcon,
    heading: "Permission",
    subheadings: ["Banner", "Vehicle Brands", "Reason", "FAQs"],
    path: "permission",
  },
  {
    icon: QrCodeIcon,
    heading: "Banner",
    path: "admin-banner",
  },
  {
    icon: QrCodeIcon,
    heading: "Vehicle Type",
    path: "admin-vehicle-type",
  },
  {
    icon: QrCodeIcon,
    heading: "Vehicle Brands",
    path: "admin-vehicle-brand",
  },
  {
    icon: QrCodeIcon,
    heading: "Vehicle Model",
    path: "admin-vehicle-model",
  },
  {
    icon: QrCodeIcon,
    heading: "Category",
    path: "admin-category",
  },
  {
    icon: QrCodeIcon,
    heading: "Reason",
    path: "admin-reasons",
  },
  {
    icon: QrCodeIcon,
    heading: "FAQs",
    path: "admin-faq",
  },
  {
    icon: QrCodeIcon,
    heading: "Coupon Code",
    path: "admin-coupon-code",
  },
  // {
  //     icon: QrCodeIcon,
  //     heading: "QR Code",
  //     path: "admin-qr-code"
  // }
];

export const CardData = [
  {
    png: "../../images/Order.png",
    count: 845,
    title: "Total Orders",
    color: "#2B6334",
  },
  {
    png: "../../images/time.png",
    count: 545,
    title: "Pending Orders",
    color: "#efb20e",
  },
  {
    png: "../../images/Truck.png",
    count: 235,
    title: "Shipping Orders",
    color: "#5d5fef",
  },
  {
    png: "../../images/Vector.png",
    count: 456,
    title: "Cancel Orders",
    color: "#f94d32",
  },
];

// const permissionData = [
//     {
//         name: 'createNewOrder',
//         palceholder: 'Create New Order'
//     }
// ]
