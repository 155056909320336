import React from "react";
import Header from "./header";
import AboutUs from "./AboutUs";
import Safety from "./Safety";
import Emergency from "./Emergancy";
import MapSection from "./MapSection";
import Distributor from "./Distributor";
import ContactUs from "./Contact";
import Footer from "./Footer";
import "./landingPage.css";

const LandingPage = () => {
  return (
    <>
      <Header />
      <AboutUs />
      <Safety />
      <Emergency />
      <MapSection />
      <Distributor />
      <ContactUs />
      <Footer />
    </>
  );
};

export default LandingPage;
