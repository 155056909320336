import React, { useEffect, useState } from "react";
// import LoginForm from "./Components/loginForm";
import Dashboard from "./Components/Dashboard/Dashboard";
import { Route, Routes, useNavigate } from "react-router-dom";
import Products from "./Components/Products/Products";
import Orders from "./Components/Orders/Orders";
import Customers from "./Components/Customers/Customers";
import Shops from "./Components/Shop/Shops";
import { QRCode } from "./Components/QRCode/QRCode";
import VehicleType from "./Components/Vehicle/VehicleType";
import VehicleBrand from "./Components/Vehicle/VehicleBrand";
import SignIn from "./Components/LoginSystem/SignIn";
import SignUp from "./Components/LoginSystem/SignUp";
import OTPVerify from "./Components/LoginSystem/OTPVerify";
import VehicleModel from "./Components/Vehicle/VehicleModel";
import Banner from "./Components/Banner/Banner";
import FAQ from "./Components/FAQ";
import Reason from "./Components/Reasons";
import Admin from "./Components/Admin";
import Category from "./Components/Category";
import Permissions from "./Components/Permission";
import ProductDetails from "./Components/Products/ProductDetails";
import CouponCode from "./Components/CouponCode";
import SosCallPage from "./Components/sosCall/sosCall";
import SosSuccessPage from "./Components/sosSuccess/sosSuccess";
import LandingPage from "./Components/LandingPage";
import axios from "axios";
import env from "./config/env";
import RedirectToApp from "./Components/redirectToApp/RedirectToApp";

function App() {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const verifyToken = async (token) => {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-token`, {
          // const { data } = await axios.post(`http://localhost:8000/api/verify-token`, {
          token
        });

        console.log(data)

        if (data.success) {
          setIsLoggedIn(true)
        }
        else {
          localStorage.removeItem("token");     //Remove invalid token
          navigate('/login')
        }

      } catch (error) {
        console.log(error)
      }


    }

    const token = localStorage.getItem("token")
    if (token) {
      verifyToken(token)
    } else {
      setIsLoggedIn(false)
    }

  }, [navigate])

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/demo" element={<Dashboard />} />
      {/* <Route path="/" exact element={<LoginForm />} /> */}
      <Route path="/login" exact element={<SignIn />} />
      <Route path="/sign-up" exact element={<SignUp />} />
      <Route path="/otp-verify" exact element={<OTPVerify />} />
      {/* <Route path="/admin" element={<AdminPage />} /> */}
      {/* <Route path="/sos-call/:qrcodeId" element={<RedirectToApp/>} /> */}


      <>
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin-customers" element={<Customers />} />
        <Route path="/admin-shop" element={<Shops />} />
        <Route path="/admin-qr-code" element={<QRCode />} />
        <Route path="/admin-dashboard" element={<Orders />} />
        <Route path="/admin-dashboard-products" element={<Products />} />
        <Route path="/admin-product-details" element={<ProductDetails />} />
        <Route path="/admin-orders" element={<Orders />} />
        <Route path="/admin-vehicle-type" element={<VehicleType />} />
        <Route path="/admin-vehicle-brand" element={<VehicleBrand />} />
        <Route path="/admin-vehicle-model" element={<VehicleModel />} />
        <Route path="/admin-banner" element={<Banner />} />
        <Route path="/admin-FAQ" element={<FAQ />} />
        <Route path="/admin-reasons" element={<Reason />} />
        <Route path="/admin-category" element={<Category />} />
        <Route path="/permission" element={<Permissions />} />
        <Route path="/admin-coupon-code" element={<CouponCode />} />
      </>
      <Route path="/sos-call/:qrcodename" element={<SosCallPage />} />
      <Route path="/success" element={<SosSuccessPage />} />
    </Routes>
  );
}

export default App;
