import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Sidebar from '../Sidebar/Sidebar';
import Logo from '../../images/logo.png';
import Image from '../../images/arrow left.png';
import Product from '../../images/productDetails.png';
import Star from '../../images/star.png';
import ReactStars from "react-rating-stars-component";

const ProductDetails = () => {
    const [rating, setRating] = useState('')
    const ratingChanged = (newRating) => {
        setRating(newRating);
    };
    const location = useLocation();
    const history = useNavigate();
    const handleDeleteProduct = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ _id: location.state._id })
        });
        const json = await response.json()
        if (json.status === 'Success') {
            history('/admin-shop')
        }
    }

    const [reviewData, setReviewData] = useState([])
    const getReviews = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reviews/` + location.state._id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json()
        if (json.status === 'Success') {
            setReviewData(json.data)
        }
    }

    const [data, setData] = useState(location.state)
    const [validated, setValidated] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(e.currentTarget);
        // for(var key of formData.entries()){
        //     console.log(key);
        // }
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
            form.classList.add('was-validated')
            setValidated(true)
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: formData
            });
            const json = await response.json()
            console.log(json)
            if (json.status === 'Success') {
                document.getElementById('btn-close').click()
                setData(formData)
            }
        }
    }

    const handleReviewSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(e.currentTarget);
        for(var key of formData.entries()){
            console.log(key);
        }
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
            form.classList.add('was-validated')
            setValidated(true)
        } else {
            console.log(formData)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reviews`, {
                method: (type === 'Add')?'POST':'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: formData
            });
            const json = await response.json()
            console.log(json)
            if (json.status === 'Success') {
                document.getElementById('btn-close').click()
                getReviews()
            }
        }

    }
    const [categoryData, setCategoryData] = useState([]);
    const getCategory = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/category`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json()
        if (json.status === 'Success') {
            setCategoryData(json.data)
        }
    }

    const [reviewForm, setReviewForm] = useState({
        name: '', reviewText:'', _id:'', starRating: ''
    })
    const [type, setType] = useState('');
    const editReview = async (item) => {
        setType('Edit')
        setReviewForm(item)
    }
    const addReview = async () => {
        setType('Add')
        setReviewForm({...reviewForm,name: '', reviewText:'',_id:'', starRating: ''})
    }

    const removeReview = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reviews`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ _id: id })
        });
        const json = await response.json()
        console.log(json)
        // if (json.status === 'Success') {
            // getReviews()
        // }
    }
    useEffect(() => {
        getReviews()
        getCategory()
    }, [])
    const starImg = (i) => {
        if (i === 1) {
            return <img src={Star} alt='' height={17} />;
        } else if (i === 2) {
            return <div className='d-flex'>
                <img src={Star} alt='' height={17} />
                <img src={Star} alt='' height={17} /></div>;
        } else if (i === 3) {
            return <div className='d-flex'><img src={Star} alt='' height={17} />
                <img src={Star} alt='' height={17} />
                <img src={Star} alt='' height={17} /></div>;
        } else if (i === 4) {
            return <div className='d-flex'><img src={Star} alt='' height={17} />
                <img src={Star} alt='' height={17} /><img src={Star} alt='' height={17} />
                <img src={Star} alt='' height={17} /></div>;
        } else {
            return <div className='d-flex'><img src={Star} alt='' height={17} /><img src={Star} alt='' height={17} />
                <img src={Star} alt='' height={17} /><img src={Star} alt='' height={17} />
                <img src={Star} alt='' height={17} /></div>;
        }
    }
    return (
        <>
            <div className='admin-root1-div'>
                <div className='admin-navbar'>
                    <div className='admin-company'>
                        <div className='admin-logo'>
                            <img className='admin-logo-icon' src={Logo} alt='' />
                        </div>
                        <span className='admin-heading'>
                            SAFEWENT
                        </span>
                    </div>

                    <div className='admin-wrapper'>
                        <input className='search-bar' type="text" placeholder='Search...' />
                        <NotificationsNoneIcon className='noti' />
                    </div>
                </div>
                <div className='admin-body'>
                    <Sidebar />

                    <div className='maindash-orders'>
                        <div className='content-area'>
                            <div className='heading'>
                                <img src={Image} alt='' />
                                <div className='text w-50'>Product Detail</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <div className='product-card d-flex align-items-center justify-content-center'>
                                                <img src={Product} className="card-img-top1" alt="..." height={170} width={120} />
                                            </div>
                                            <div className="card-body">
                                                <div className="card-title">{location.state.name}</div>
                                                <strong className="card-text">${location.state.price}</strong><br />
                                                <img src={Star} alt='' height={17} /> {location.state.reviews.map((review) => (<span>{review.starRating}</span>))}
                                            </div>
                                            <p className='product-desc'>{location.state.description}</p>
                                        </div>
                                        <div className='col-5'>
                                            <Link to={'/admin-shop'} className='btn btn-success'><i className="bi bi-arrow-left"></i></Link>
                                            <button className='btn btn-success mx-2' onClick={() => handleDeleteProduct()}><i className="bi bi-trash-fill"></i></button>
                                            <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#addCustomerModal"><i className="bi bi-pencil-fill"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='review-head'>
                                            <h5>Ratings & Reviews</h5>
                                            <span><img src={Star} alt='' height={17} />  2k Ratings & {reviewData && reviewData.length} Reviews</span>
                                        </div>
                                        <button className='btn btn-outline-success rounded-pill px-2' onClick={() => addReview()} data-bs-toggle="modal" data-bs-target="#add-review">+ Add Review</button>
                                    </div>
                                    {reviewData && reviewData.map((item, index) => (
                                        <div className='review-content mt-3' key={index}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='user-detail d-flex'>
                                                    <div className='user-profile'>
                                                        <img src='https://dummyimage.com/45x45/ddd/aaa' className='rounded-circle' />
                                                    </div>
                                                    <div className='user mx-1'>
                                                        <div className='user-name'>{item.name}</div>
                                                        <span className='review-date'>{item.createdAt}</span>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    {starImg(Math.round(item.starRating))}
                                                    <div className="btn-group dropstart">
                                                        <i className="bi bi-three-dots-vertical btn" data-bs-toggle="dropdown"></i>
                                                        <ul className="dropdown-menu">
                                                            <li className="btn dropdown-item" data-bs-toggle="modal" data-bs-target="#add-review" onClick={() => editReview(item)}><i className="bi bi-pencil-square"></i> Edit</li>
                                                            <li className='btn dropdown-item' onClick={() => removeReview(item._id)}><i className="bi bi-trash-fill"></i> Delete</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='review-text'>{item.reviewText}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* edit product  */}

            <div className="modal fade" id="addCustomerModal" tabIndex="-1" aria-labelledby="addCustomerModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addCustomerModalLabel">Edit Product</h1>
                            <button type="button" className="btn-close" id='btn-close' data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} className='needs-validation' noValidate validated={validated.toString()} method="post" encType="multipart/form-data">
                                <input name='_id' type='hidden' value={data._id} />
                                <div className='mb-3'>
                                    <input className="form-control input-field" type="file" name="productPicture" id="formFile" required="" multiple />
                                </div>
                                <div className="mb-3">
                                    <input type="name" name='name' value={data.name} onChange={e => setData({ ...data, name: e.target.value })} className="form-control input-field" placeholder="Product Name" required />
                                </div>
                                <div className="mb-3">
                                    <input type="number" name='price' value={data.price} onChange={e => setData({ ...data, price: e.target.value })} className="form-control input-field" placeholder="Product Price" required />
                                </div>
                                <div className="mb-3">
                                    <input type="number" name='discountedPrice' value={data.discountedPrice} onChange={e => setData({ ...data, discountedPrice: e.target.value })} className="form-control input-field" placeholder="Product Discount Price" required />
                                </div>
                                <div className='mb-3'>
                                    <select className="form-select input-field" name='categoryId' value={data.categoryId} onChange={e => setData({ ...data, categoryId: e.target.value })}>
                                        <option value='' disabled key={0}>Open this select menu</option>
                                        {categoryData && categoryData.map((item, i) => (
                                            <option key={i + 1} value={item._id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='mb-3'>
                                    <textarea className="form-control input-field" name='description' value={data.description} onChange={e => setData({ ...data, description: e.target.value })} placeholder='Product Description' rows="3"></textarea>
                                </div>
                                <button type="submit" className="btn btn-success w-100 rounded-pill my-2 py-2">Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Review  */}
            <div className="modal fade" id="add-review" tabIndex="-1" aria-labelledby="add-reviewLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="add-reviewLabel">{type} Review</h1>
                            <button type="button" className="btn-close" id='btn-close' data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleReviewSubmit} className='needs-validation' noValidate validated={validated.toString()} method="post" encType="multipart/form-data">
                                <input name='_id' type='hidden' value={reviewForm._id} />
                                <input name='productId' type='hidden' value={data._id} />
                                <input name='starRating' type='hidden' value={rating} />
                                <ReactStars count={5}
                                    onChange={ratingChanged} size={45} //isHalf={true} 
                                    emptyIcon={<i className="bi bi-star"></i>}
                                    halfIcon={<i className="bi bi-star-half"></i>}
                                    fullIcon={<i className="bi bi-star"></i>} activeColor="#ffd700" />,
                                <div className='mb-3'>
                                    <input className="form-control input-field" type="file" name="productPicture" id="formFile" required="" />
                                </div>
                                <div className="mb-3">
                                    <input type="name" name='name' value={reviewForm.name} onChange={e => setReviewForm({...reviewForm, name: e.target.value})} className="form-control input-field" placeholder="User Name" required />
                                </div>
                                <div className='mb-3'>
                                    <textarea className="form-control input-field" name='reviewText' value={reviewForm.reviewText} onChange={e => setReviewForm({...reviewForm, reviewText: e.target.value})} placeholder='Enter Review' rows="4"></textarea>
                                </div>
                                <button type="submit" className="btn btn-success w-100 rounded-pill my-2 py-2">Save Review</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetails