import axios from "../helpers/axios";

export const fetchSosCallDetails = async ({ qrcodename }) =>
  axios.get(`/api/soscall/${qrcodename}`);

export const sendSosCall = async (formData) =>
  axios.post("/api/soscall", formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
export const getReasons = async () => axios.get("/api/reasons");
