import React,{useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Table.css';
import axios from "../../helpers/axios";
import Product from '../../images/product.png';

function createData(name, calories, fat, carbs, protein, total) {
    return { name, calories, fat, carbs, protein, total };
}

const getOrders = async (e) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/order`,
    {
        headers: {
            'Authorization': 'Bearer '+ localStorage.getItem('token'),
        }
    });
    console.log(res.data);
}

const rows = [
    createData('#001ORD', 'Gray Motorcycle Helmet', '14, Feb. 2023', '16, Feb. 2023', 'Delivered', '$123'),
    createData('#001ORD', 'Gray Motorcycle Helmet', '14, Feb. 2023', '16, Feb. 2023', 'Shipped', '$123'),
    createData('#001ORD', 'Gray Motorcycle Helmet', '14, Feb. 2023', '16, Feb. 2023', 'Patched', '$123'),
    createData('#001ORD', 'Gray Motorcycle Helmet', '14, Feb. 2023', '16, Feb. 2023', 'Canceled', '$123')
];

const makeStyle = (status) => {
    if (status === 'Delivered') {
        return {
            background: 'rgb(145 254 159 / 47%)',
            color: 'green',
            padding: '8px 12px'
        }
    }
    else if (status === 'Shipped') {
        return {
            color: '#EFB20E',
            padding: '8px 12px',
            background: '#FDF7E7',
        }
    }
    else if (status === 'Patched') {
        return {
            color: '#EFB20E',
            padding: '8px 12px',
            background: '#FDF7E7',
        }
    }
    else if (status === 'Canceled') {
        return {
            background: '#FBEBE9',
            color: '#F94D32',
            padding: '8px 12px'
        }
    }
}

export default function BuildTable() {
    useEffect(() => {
        getOrders()
    });
    return (
        <div className='table'>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className='table-head' >
                        <TableRow >
                            <TableCell>Order Number</TableCell>
                            <TableCell align="right">Product</TableCell>
                            <TableCell align="right">Order Date</TableCell>
                            <TableCell align="right">Delivery Date</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Total</TableCell>
                            <TableCell align="right"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                className='table-rows'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell className='onumber ' component="th" scope="row">
                                    <span className='childTitle'>  {row.name}</span>
                                </TableCell>
                                <TableCell className='product' align="right">
                                    <div className='parentItem'>
                                        <div className='childImage-div'>
                                            <img className='childImage' src={Product} alt='' />
                                        </div>
                                        <div className='childTitle'>{row.calories}</div>
                                    </div>
                                </TableCell>
                                <TableCell className='odate' align="right">
                                    <span className='childTitle'>{row.fat}</span>
                                </TableCell>
                                <TableCell className='ddate' align="right">
                                    <span className='childTitle'>{row.carbs}</span></TableCell>
                                <TableCell className='ostatus' align="right">
                                    <span className='status' style={makeStyle(row.protein)}>{row.protein}</span>
                                </TableCell>
                                <TableCell className='childTitle' align="right">{row.total}</TableCell>
                                <TableCell className='childTitle' align="right">...</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
