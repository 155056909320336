import React, { useState, useEffect } from 'react'
import couponImg from '../../images/coupon-img.png'
import './Coupon.css'

const Coupon = () => {
    const [type, setType] = useState('')
    const [data, setData] = useState('')

    const getCouponCode = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/discount`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json()
        if (json.status === 'Success') {
            setData(json.data)
        }
    }

    const [dataArr, setDataArr] = useState({ discountName: '', description: '', percentDiscount: '', maxCap: '', maxUsage: '', expiryDate: '' })
    const [validated, setValidated] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget
        if (!form.checkValidity()) {
            e.stopPropagation()
            form.classList.add('was-validated')
            setValidated(true)
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/discount`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataArr)
            });
            const json = await response.json()
            console.log(json)
            if (json.status === true) {
                document.getElementById('btnclose').click()
                getCouponCode()
            }
        }
    }

    const deleteCoupon = async (rid) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/discount`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ _id: rid })
        });
        const json = await response.json()
        if (json.status === 'Success') {
            getCouponCode()
        }
    }

    useEffect(() => {
        getCouponCode()
    }, [])
    return (
        <>
            <div className='maindash-orders'>
                <div className=' w-100 d-flex align-items-center justify-content-between'>
                    <div className='heading-customers'>Coupon</div>
                    <div className='d-flex'>
                        <input className='search-items' placeholder='Search Customer..' />
                        <button className='btn btn-success rounded-pill' onClick={() => setType('Add')} data-bs-toggle="modal" data-bs-target="#add-coupon">+ Add</button>
                    </div>
                </div>
                <div className='row w-100'>
                    {data && data.map((item, index) => (
                        <div className='col-4' key={index}>
                            <div className='coupon-card mb-3' style={{ backgroundImage: `url(${couponImg})` }}>
                                <div className='coupon-content'>
                                    <div>
                                        <h5 className='text-light'>{item.discountName}</h5>
                                        <p className='text-light mb-0'>Max Cap: {item.maxCap}</p>
                                        <p className='text-light mb-0'>{(item.description.length < 30) ? item.description : item.description.substring(0, 30) + '...'}</p>
                                    </div>
                                    <div className='coupon-name-area'>
                                        <h5 className='coupon-name'>{item.discountName}</h5>
                                        <button className='btn btn-sm btn-danger delete-btn-coupon' onClick={() => deleteCoupon(item._id)}><i className='bi bi-trash-fill'></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="modal fade" id="add-coupon" tabIndex="-1" aria-labelledby="add-couponLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="add-couponLabel">{type} Coupon</h1>
                            <button type="button" className="btn-close" id='btnclose' data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} className='needs-validation' noValidate validated={validated.toString()} method="post">
                                <div className="mb-3">
                                    <input type="name" name='discountName' value={dataArr.discountName} onChange={e => setDataArr({ ...dataArr, discountName: e.target.value })} className="form-control input-field" id="username" placeholder="Enter coupon code" required />
                                </div>
                                <div className="mb-3">
                                    <input type="number" name='percentDiscount' value={dataArr.percentDiscount} onChange={e => setDataArr({ ...dataArr, percentDiscount: e.target.value })} className="form-control input-field" placeholder="Enter discount percent" required />
                                </div>
                                <div className="input-group mb-3">
                                    <input type="number" name='maxCap' className="form-control input-field" value={dataArr.maxCap} onChange={e => setDataArr({ ...dataArr, maxCap: e.target.value })} placeholder='Enter maximum number' required />
                                </div>
                                <div className="input-group mb-3">
                                    <input type="date" name='expiryDate' className="form-control input-field" value={dataArr.expiryDate} onChange={e => setDataArr({ ...dataArr, expiryDate: e.target.value })} placeholder='Expiry date' required />
                                </div>
                                <div className='mb-3'>
                                    <textarea className="form-control input-field" name='description' value={dataArr.description} onChange={e => setDataArr({ ...dataArr, description: e.target.value })} placeholder="Enter description" rows={3}></textarea>
                                </div>

                                <button type="submit" className="btn btn-success w-100 rounded-pill my-1 py-2">Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Coupon