import React, { useEffect, useState } from "react";
import "./OrderDetails.css";
import { Link, useLocation } from "react-router-dom";
import Image from "../../images/arrow left.png";
import ProductImage from "../../images/productDetails.png";
import PrintImage from "../../images/print.png";
import InvoiceImage from "../../images/invoicePrint.png";
import { apiCaller } from "../../utils/apiCaller";

const OrderDetails = () => {
  const { state } = useLocation();
  console.log(state, "state");
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrderById = async () => {
      try {
        const { result } = await apiCaller.get(`/orders/${state._id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setOrder(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrderById();

    return () => setOrder(null);
  }, []);

  if (!order) {
    return <></>;
  }

  return (
    <div className="maindash-orders">
      <div className="content-area">
        <div className="heading">
          <Link to="/admin-dashboard">
            <img src={Image} alt="" />
          </Link>
          <div className="text">Order Detail</div>
        </div>
        <div className="ordersAndInvoice">
          <div className="order-details">
            <div className="p">
              <div className="sub-heading">
                <p className="ID">
                  ID : {order.ordernumber ? order.ordernumber : "#001ORD"}
                </p>
                <div className="text-status">
                  <p className="Shipped">
                    {order.status ? order.status : "Shipped"}
                  </p>
                </div>
              </div>

              <div className="products">
                {order.productList &&
                  order.productList.map((item, index) => (
                    <div className="product-details" key={index}>
                      <div className="image">
                        <div className="sub-image">
                          <img
                            className="image-of-product"
                            src={ProductImage}
                            alt=""
                          />
                        </div>
                      </div>

                      <div className="detail">
                        <div className="all-details">
                          <div className="d">
                            <p className="t1">{item.name}</p>
                            <p className="t2">{item.price}</p>
                            <p className="t3">Qty :1</p>
                          </div>
                          <p className="track mt-3">
                            Order can track by +91 123 456 7890
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="details">
              <div className="sub-details">
                <div className="div-1">
                  <p className="div-1-1">Order Details</p>
                </div>
                <div className="div-2">
                  <div className="nested-div">
                    <p className="user">User</p>
                    <p className="jaydip">{order.customerId.name}</p>
                  </div>
                </div>
                <div className="div-2">
                  <div className="nested-div">
                    <p className="user">Address</p>
                    <p className="address">
                      {`
                      ${order.shippingAddressId.streetAdd},
                       ${order.shippingAddressId.city},
                        ${order.shippingAddressId.state},
                         ${order.shippingAddressId.pincode}`}
                    </p>
                  </div>
                </div>
                <div className="div-2">
                  <div className="nested-div">
                    <p className="Contact">Contact</p>
                    <p className="phone">{order.customerId.phone}</p>
                  </div>
                </div>
              </div>
              <div className="sub-details2">
                <div className="div-3">
                  <p className="div-1-1">Order Details</p>
                </div>
                <div className="amount1">
                  <div className="price1">
                    <p className="a1">Amount</p>
                    <p className="p1">{`$${order.orderAmount || 0}`}</p>
                  </div>
                  <div className="price1">
                    <p className="a2">Shipping Cost</p>
                    <p className="p2">$10</p>
                  </div>
                  <div className="price1">
                    <p className="a3">Tax</p>
                    <p className="p3">$20</p>
                  </div>
                </div>
                <div className="amount2">
                  <div className="price2">
                    <p className="a1">Sub Total</p>
                    <p className="p1">$130</p>
                  </div>
                  <div className="price3">
                    <p className="a30">Coupon Code</p>
                    <p className="p30">$30</p>
                  </div>
                </div>
                <div className="amount3">
                  <div className="total-cost">
                    <p>Total</p>
                    <p className="user-total">$130</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice">
            <div className="invoice-heading">
              <p className="in-text">Invoice</p>
            </div>
            <img src={InvoiceImage} className="invoice-dcoument" alt="" />
            <div className="invoice-btn">
              <div className="print">
                <img src={PrintImage} className="print-img" alt="" />
              </div>
              <p className="Print1">Print</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
