import "./input.css";
import React, { useId } from "react";

function InputBox({ label, ...otherProps }) {
  const id = useId();
  return (
    <div className="inputBox">
      <label htmlFor={id}>{label}:</label>
      <input id={id} {...otherProps} />
    </div>
  );
}

export default InputBox;
