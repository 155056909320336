import React, { useState } from "react";
import "./Login.css";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link, useNavigate } from "react-router-dom";
import env from "../../config/env";

const SignIn = () => {
  let history = useNavigate();
  const [phone, setPhone] = useState("");
  const [validated, setValidated] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(false);
    const response = await fetch(`${env.apiUrl}/api/user/otp-generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ countryCode: "91", phone: phone }),
    });
    const json = await response.json();
    console.log(json);
    if (json.status === "Success") {
      localStorage.setItem("phone", phone);
      // localStorage.setItem('countryCode', countryCode);
      history("/otp-verify");
    } else {
      alert(json.message);
    }
  };
  return (
    <>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <h1 className="text-center">Sign in</h1>
              <p className="text-center text-14-500 text-gray3">
                Take the next step and sign in to your account
              </p>

              <form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit}
                validated={validated.toString()}
                method="post"
              >
                {/* <div className="form-outline mb-4">
                                    <label className="form-label">Phone Number</label>
                                    <PhoneInput
                                        country={"eg"}
                                        enableSearch={true}
                                        value={phone}
                                        minLength={10}
                                        onChange={(phone) => setPhone(phone)} required
                                    />
                                </div> */}
                <div className="input-group mb-3">
                  <span
                    className="input-group-text code"
                    id="inputGroup-sizing-sm"
                  >
                    +91
                  </span>
                  <input
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.currentTarget.value)}
                    className="form-control form-control-lg group"
                    placeholder="Enter mobile number"
                  />
                </div>
                <button
                  className="btn btn-success btn-lg rounded-pill w-100"
                  type="submit"
                >
                  Send OTP
                </button>
              </form>
              <p className="text-center text-muted mt-3">
                Don`t have an Account{" "}
                <Link to={"/sign-up"} className="signup-link">
                  SignUp
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
