import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
import { apiCaller } from "../../utils/apiCaller";

const ContactUs = () => {
  const [phone, setPhone] = useState("");

  return (
    <>
      <section className="contact-us">
        <Container>
          <div className="contact-card">
            <Row className="gx-4 gy-4 gy-md-4 gy-lg-0">
              <Col xs={12} lg={6}>
                <p className="mb-3">We are available for you 24/7</p>
                <h2 className="mb-4">
                  Protecting Lives, One Innovation at a Time - Safewent
                </h2>
                <p>
                  Safewent revolutionizes personal safety with innovative
                  solutions that protect and empower lives. Experience the
                  future of safety today
                </p>
              </Col>
              <Col xs={12} lg={6}>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string()
                      .max(255)
                      .required("Please enter your name"),
                    email: Yup.string()
                      .email("Invalid email")
                      .required("Please enter your email"),
                  })}
                  validate={() => {
                    const errors = {};
                    if (!phone) {
                      errors.phone = "Please enter your phone number";
                    }
                    return errors;
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    console.log("On submit");
                    try {
                      await apiCaller.post("api/user/create", {
                        body: { ...values, phone, usertype: "Customer" },
                      });
                      setSubmitting(false);
                    } catch (error) {
                      console.log(error);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form className="contact-form" onSubmit={handleSubmit}>
                      <h4 className="mb-4 text-uppercase">Stay Updated</h4>
                      <Form.Group className="mb-3">
                        <Form.Control
                          size="lg"
                          type="text"
                          name="name"
                          placeholder="Enter full name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name && (
                          <Form.Text className="text-danger">
                            {errors.name}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          size="lg"
                          name="email"
                          placeholder="Enter email address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email && (
                          <Form.Text className="text-danger">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <PhoneInput
                          country={"in"}
                          enableSearch={true}
                          value={phone}
                          placeholder="Enter your phone number"
                          minLength={10}
                          onChange={(v) => setPhone(v)}
                        />
                        {errors.phone && (
                          <Form.Text className="text-danger">
                            {errors.phone}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Button
                        disabled={isSubmitting}
                        variant="primary"
                        type="submit"
                      >
                        Send Message
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
