import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Logo from "../../images/logo.png";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const VehicleBrand = () => {
  const [vehicletype, setVehicletype] = useState([]);

  const fetchVehicleType = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/vehicletype`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.status === "Success") {
      setVehicletype(json.data);
    }
    // console.log(json.data)
  };

  const [dataArr, setDataArr] = useState([]);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/vehicletypebrand`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    if (json.status === true) {
      setDataArr(json.data);
    }
  };

  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    name: "",
    vehicleTypeId: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add("was-validated");
      setValidated(true);
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/vehiclebrand`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();
      if (json.status === "Success") {
        fetchData();
        setData({ ...data, name: "", vehicleTypeId: "" });
        setValidated(false);
      }
      console.log(json);
    }
  };
  const handleDeleteVehiclebrand = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/vehiclebrand`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: id }),
      }
    );
    const json = await response.json();
    if (json.status === "Success") {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
    fetchVehicleType();
  }, []);

  return (
    <>
      <div className="admin-root1-div">
        <div className="admin-navbar">
          <div className="admin-company">
            <div className="admin-logo">
              <img className="admin-logo-icon" src={Logo} alt="" />
            </div>

            <span className="admin-heading">SAFEWENT</span>
          </div>

          <div className="admin-wrapper">
            <input className="search-bar" type="text" placeholder="Search..." />
            <NotificationsNoneIcon className="noti" />
          </div>
        </div>

        <div className="admin-body">
          <Sidebar index={8} />
          {/* content start  */}
          <div className="maindash-orders">
            <div className="w-100">
              <div className="heading-customers">Vehicle Brands</div>
            </div>
            <form
              onSubmit={handleSubmit}
              className="needs-validation w-100"
              noValidate
              validated={validated.toString()}
              method="post"
            >
              <div className="row">
                <div className="col-4">
                  <select
                    className="form-select input-select form-control"
                    name="vehicleTypeId"
                    value={data.vehicleTypeId}
                    onChange={(e) =>
                      setData({ ...data, vehicleTypeId: e.target.value })
                    }
                    style={{ height: "42px", borderRadius: "14px" }}
                    required
                  >
                    <option value="">Select vehicle type</option>
                    {vehicletype &&
                      vehicletype.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-4">
                  <input
                    className="search-items input-field form-control w-100 form-control-lg"
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    placeholder="Enter brand name"
                    required
                  />
                </div>
                <div className="col-4">
                  <button
                    type="submit"
                    className="btn btn-success rounded-pill px-4"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>

            {dataArr &&
              dataArr.map(
                (item) =>
                  item.vehiclebrand != "" && (
                    <div className="row w-100">
                      <h5>{item.name}</h5>
                      {dataArr &&
                        item.vehiclebrand.map((items) => (
                          <div className="col-3" key={items._id}>
                            <li
                              className="list-group-item d-flex justify-content-between align-items-start mb-3"
                              style={{ borderRadius: "10px" }}
                            >
                              <div className="ms-1 me-auto d-flex">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  onClick={() =>
                                    handleDeleteVehiclebrand(items._id)
                                  }
                                  defaultChecked
                                />
                                <div className="fw-bold ms-1">{items.name}</div>
                              </div>
                              {/* <span className="badge bg-primary rounded-pill">14</span> */}
                            </li>
                          </div>
                        ))}
                    </div>
                  )
              )}
          </div>
          {/* content end  */}
        </div>
      </div>
    </>
  );
};

export default VehicleBrand;
