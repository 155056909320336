import "./sosSuccess.css";
import React from "react";
import doneImg from "../../assests/done.png";
import { useNavigate } from "react-router-dom";

function SosSuccessPage() {
  const navigate = useNavigate();
  return (
    <div className="sosSuccessPage">
      <img src={doneImg} alt="" />
      <h4 className="success-msg">Request sent successfully</h4>
      <button className="backBtn" onClick={() => navigate(-1)}>
        Go Back
      </button>
    </div>
  );
}

export default SosSuccessPage;
