import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MapImg from "../../images/maps.png";

const Map = () => {
  return (
    <>
      <section className="map-section clearfix">
        <div className="map-section-left-col">
          <img src={MapImg} alt="" />
        </div>
        <div className="map-section-right-col">
          <Container>
            <Row>
              <Col xs={12}>
                <h3 className="mb-4">Group Tracking</h3>
                <p className="mb-4">
                  SafeWent's Group Tracking feature allows users to stay connected and track the real-time location of their friends or family members within a designated group. Whether you're planning an outing, attending an event, or simply want to ensure everyone's safety during travel, Group Tracking provides peace of mind.
                </p>
                <ul>
                  <li className="mb-3">
                    Users can create groups and invite members to join.
                  </li>
                  <li className="mb-3">
                  Once the group is formed, each member's location can be tracked on a map in real-time.
                  </li>
                  <li className="mb-3">
                    This feature is particularly useful for coordinating meetups, ensuring everyone stays together, and providing assistance if someone gets lost or faces any emergency.
                  </li>
                  <li className="mb-3">
                    With SafeWent's Group Tracking, you can easily keep tabs on your loved ones and enjoy a worry-free experience, knowing that you're connected and aware of each other's whereabouts.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Map;
