import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AboutImg from "../../images/aboutus.jpg";

const AboutUs = () => {
  return (
    <>
      <section>
        <Container>
          <Row className="align-items-center gx-5 gy-4 gy-md-4 gy-lg-0">
            <Col xs={12} md={12} lg={6}>
              <h2>About Us</h2>
              <p>
                At Safewent, we are passionate about your safety and well-being. We believe that everyone deserves to feel secure and protected, no matter where they are. With this vision in mind, we have developed Safewent, the ultimate personal safety app.
              </p>
              <br/>
              <p>
                Our mission is to empower individuals to take control of their safety in various situations. Whether you're walking alone at night, traveling to new places, or engaging in outdoor activities, Safewent is here to provide you with peace of mind.
              </p>
              <br/>
              <p>With a range of innovative features, Safewent ensures that you have the tools you need to stay safe. From accident alerts that notify your emergency contacts in times of need to finding nearby blood banks during emergencies, we've got you covered.</p>
              <p>We also offer insurance coverage for unexpected medical expenses or accidents, along with parking alerts to help you avoid those frustrating parking tickets. With group tracking, you can keep an eye on the real-time location of your loved ones, ensuring their safety as well.</p>
              
              <br/>
              <p>Join us on this journey towards a safer future. Download Safewent today and take control of your safety. Your well-being is our top priority</p>
            </Col>
            <Col xs={12} md={12} lg={6}>
                <img src={AboutImg} alt=""/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutUs;
